<template>
  <div>
    <div class="middles">
      <img class="avatar" :src="this.currentImage" alt="" />

      <!-- <i class="el-icon-arrow-left f-s-28"></i> -->

      <el-carousel @change="carouselChange" height="150px" style="width: 100%">
        <el-carousel-item v-for="(item, index) in textArr" :key="index">
          <div class="text-row" style="text-align: center">
            "{{ item.title }}"
          </div>
        </el-carousel-item>
      </el-carousel>

      <!-- <i class="el-icon-arrow-right f-s-28"></i> -->

      <!-- <div class="f-s-20 PP-B">here some words here some words here.”</div> -->

      <div class="PP-B" style="margin-top: 27px">
        {{ textArr[currentCard].name }}
        <!-- Frank Smith -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  methods: {
    carouselChange(e) {
      this.currentCard = e;
      this.currentImage = this.textArr[e].avatar;
    },
  },
  data() {
    return {
      currentCard: 0,
      currentImage: '../assets/image/middle.png',
      textArr: [
        {
          avatar: require('../assets/image/amanda.jpg'),
          title: `WAVF has been a game-changer for my online store. The platform is user-friendly, the products are
							top-notch, and the delivery is quick. Adding a new SKU was a breeze, and my customers love it.
							Highly recommend WAVF for a seamless wholesale experience`,
          name: "Amanda Woodtuff",
        },
        {
          avatar: require('../assets/image/frank.jpg'),
          title: `WAVF offers top-quality products at great prices. I've been impressed by the value they provide, making it easy to offer premium items to my customers without breaking the bank. Highly recommend WAVF for quality and affordability!`,
          name: "Frank Smith",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  border-radius: 50%;
}
.middles {
  .text-row {
    // border:1px solid red;
    width: 80%;
    margin: 0px auto;
    /** 文本1 */
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 43.44px;
    color: rgba(255, 255, 255, 1);
    /** 文本1 */
    text-align: center;
    vertical-align: top;
    font-size: 22px;
    @media (max-width: 1000px) {
      margin-top: 10px;
      font-size: 16px !important;
      line-height: 18px !important;
    }
  }
  color: #fff;
  width: 100%;
  min-width: 1330px;
  @media (max-width: 1000px) {
    min-width: 100% !important;
  }
  // height: 300px;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  background: linear-gradient(
    360deg,
    rgba(64, 96, 85, 1),
    rgba(222, 229, 211, 1)
  );

  img {
    width: 100px;
    height: 100px;
  }
}
</style>
