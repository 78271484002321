//封装axios
import axios from "axios";
import Vue from "vue";
import { Message, Loading } from "element-ui";
import router from "../router";
// import { translate } from "./translate";
// 封装loading开启和结束函数
let loading;
function startLoading() {
	loading = Loading.service({
		lock: true,
		text: "拼命加载中...",
		background: "rgba(0,0,0,0.7)",
	});
}
function endLoading() {
	loading.close();
}

let baseURL = process.env.VUE_APP_API_BASE_URL

Vue.prototype.baseURL = baseURL;
//1: 创建axios实例
const service = axios.create({
	//基地址
	baseURL: baseURL,
	//baseURL:env.dev.baseUrl,
	settimeout: 5000,
});
//2:请求拦截
service.interceptors.request.use(
	(config) => {
		//在发送请求之前做些什么，比如验证token之类的
		if (localStorage.getItem("token")) {
			config.headers.token = localStorage.getItem("token");
		}
		//开启loading
		// startLoading();
		return config;
	},
	(error) => {
		//对错误请求做些什么
		//关闭loading
		// endLoading();
		return Promise.reject(error);
	}
);

//3:响应拦截
service.interceptors.response.use(
	(response) => {
		if (response.data.code == 2) {
			// Message.error("Please log in");
			// router.push("/login");
		}
		// if(response.data.code == 1){
		// 	Message.error(response.data.msg);
		// }

		try {
			let lang = sessionStorage.getItem("translateT"); //页面刷新后能获取到之前设置的语种
			let time = null;
			if (lang) {
				if (time) {
					clearTimeout(time);
				}
				time = setTimeout(() => {
					clearTimeout(time);
					translate.language.setDefaultTo(lang);
					translate.service.use("client.edge");
					translate.execute();
				}, 500);
			}
		} catch (error) {}
		//关闭loading
		// endLoading();
		return response;
	},
	(error) => {
		//对错误请求做些什么
		// const { status } = error.response;
		// if (status == 401) {
		//   Message.error("用户过期，请重新登录！");
		//   localStorage.removeItem("eleToken");
		//   router.push("/login");
		// }
		//关闭loading
		// endLoading();
		//提示信息
		// Message.error(error.response.data.msg);
		// return Promise.reject(error);
	}
);
//4:抛出axios对象实例
export default service;
