<template>
	<div class="trending">
	  <div class="VC-B" style="font-size: 52px; margin-bottom: 25px">
	    Trending
	  </div>
	  <div class="flex juc-center f-wrap" style="width: 80%">
	    <div
	      class="PP-B"
	      v-for="item in list" @click.stop="searchByText(item)"
	      
	      style=" 
	        margin: 0px 9px 10px 0;
	        border: 1px solid #000;
	        border-radius: 50px;
	        padding: 8px 26px 8px 27px;cursor: pointer;
	      "
	    >
	      {{item}}
	    </div>
	  </div>
	  <div style="margin-top: 25px;" class="t-d-u c-p PP-R" >
	  	See All Trending Item
	  </div>
	</div>
</template>

<script>
	export default {
		inject: ["reload"],
	  props: {
	  },
	  data(){
		  return {
			  list:[
				  "Hand Made",
				  "Designer", "Eco Friendly", "Women Owned", "Aisan Owned", "WAVF Selection" 
			  ]
		  }
	  },
	  methods: {
		  searchByText(str){
			  let that = this;
			  this.$router.replace({
			  		name: "search",
			  		query: {
			  			search: str
			  		},
			  	},
			  	() => {
			  		that.reload(); //刷新页面
			  	}
			  );
		  },
	  },
	};
</script>

<style lang="scss" scoped>
	.trending {
	  margin: 82px auto 0;
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;
	}
</style>