<template>
  <div class="contiun">
    <div class="main-b">
      <div class="item">
        <img style="margin-bottom: 20px" src="../assets/Logo.png" alt="" />
        <div style="margin-bottom: 5px">WAVF is an online B2B</div>
        <div style="margin-bottom: 5px">MARKETPLACE,Provides one-stop</div>
        <div style="margin-bottom: 5px">North Americhan hot-selling</div>
        <div style="margin-bottom: 5px">product supply chain services.</div>
        <div class="flex al-item" style="margin-top: 8px">
          <img
            style="width: 25px; height: 25px"
            src="../assets/image/Company.png"
            alt=""
          />
          <div class="mag-l-15" style="color: #b09fa5">
            3 Keensford Court, Unit 2,
            <div>Ajax, Ontario,L1Z 0K4</div>
            <div>Canada</div>
          </div>
        </div>
        <div class="flex al-item" style="margin-top: 8px">
          <img
            style="width: 25px; height: 25px"
            src="../assets/image/Email.png"
            alt=""
          />
          <div class="mag-l-15" style="color: #b09fa5">Info@wavf.com</div>
        </div>
        <div class="flex al-item" style="margin: 30px 0 0 -5px">
          <img
            class="mag-r-10"
            style="width: 35px; height: 27px"
            src="../assets/image/Meta.png"
            alt=""
          />
          <img
            class="mag-r-10"
            style="width: 35px; height: 27px"
            src="../assets/image/LinkedIn.png"
            alt=""
          />
          <img
            class="mag-r-10"
            style="width: 35px; height: 27px"
            src="../assets/image/Instagram.png"
            alt=""
          />
          <img
            class="mag-r-10"
            style="width: 35px; height: 27px"
            src="../assets/image/YouTube.png"
            alt=""
          />
        </div>
      </div>
      <div class="item">
        <div style="font-size: 22px; margin-bottom: 20px">SELECTION</div>
        <div class="content">Stationery</div>
        <div class="content">Toys</div>
        <div class="content">Art</div>
      </div>
      <div class="item">
        <div style="font-size: 22px; margin-bottom: 20px">COMPANY</div>
        <div class="content" @click="termsOfUse">Terms of Use</div>
        <div class="content" @click="privacyPolicy">Privacy Policy</div>
        <!-- <div class="content">Privacy Statement</div> -->
      </div>
      <div class="item">
        <div style="font-size: 22px; margin-bottom: 20px">SUPPORT</div>
        <div class="content" @click="contactUs">Contact</div>
        <div class="content" @click="support">FAQ</div>
        <!-- <div class="content">Clients Say</div> -->
      </div>
      <div class="item">
        <div style="font-size: 22px; margin-bottom: 20px">CONNECT</div>
        <div class="content" @click="openInstagram">Instagram</div>
        <div class="content" @click="openFacebook">Facebook</div>
        <div class="content" @click="goLogin" style="color: #90acea">Log In ></div>
      </div>
    </div>
    <div class="fooders">
      <div>WAVF © 2023-2024. All Rights Reserved.</div>
      <img
        style="width: 61px; height: 41px"
        src="../assets/image/Stripe.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    contactUs(){
      this.$router.push("/contact_us");
    },
    support(){
      this.$router.push("/support");
    },
    privacyPolicy(){
      this.$router.push("/policy");
    },
    openInstagram(){
      window.open("https://www.instagram.com/wavf_canada/",'_blank')
    },
    openFacebook(){
      window.open("https://www.facebook.com/wavfcanada",'_blank')
    },
    termsOfUse(){
      this.$router.push("/brands_terms_of_use");
    },
    goLogin() {
            this.$router.push("/login");
        },
  }
};
</script>

<style lang="scss" scoped>
.main-b {
  // width: 1812px;
  border-top: 1px solid #000;
  margin: 100px 54px 0;
  padding: 50px 0 11px;
  display: flex;
  justify-content: space-evenly;
  font-family: "Pp-Black";
  @media (max-width: 800px) {
    flex-direction: column;
    margin: 100px 32px 0;
  }
  .item {
    @media (max-width: 1000px) {
      margin-top: 20px;
    }
    display: flex;
    flex-direction: column;
    img {
      width: 159px;
      height: 32px;
      display: block;
    }
    .content {
      font-size: 12px;
      margin: 3px 0;
      color: #b09fa5;
      cursor: pointer;
    }
  }
}
.fooders {
  border-top: 1px solid #000;
  height: 59px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 21px 0 24px;
}
</style>
