import request from "@/utils/request";


export function commonApi(data) {
	// console.log('11', data)
	return request({
		url: `/web/index.php`,
		method: "get",
		params: data
	});
}
export async function getCatList(){
	const res = await commonApi({
		r: "api/default/cat-list"
	});
	if(res.data.code == 0){
		return res.data.data.list;
	}else{
		return null;
	}
}
export function get(data) {
	return request({
		url: `/web/index.php`,
		method: "get",
		params: data
	});
}
export function post(data) {
	return request({ 
		url: `/web/index.php?r=${data.r}`,
		method: "post",
		data: data,
		headers: {
			'Content-Type': 'application/json',
			'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
			// ...formData.getHeaders(),
			
		},
	});
}
// export function post(data) {
// 	console.log('post', data)
// 	let token = "";
// 	if (localStorage.getItem("token")) {
// 	 token = localStorage.getItem("token");
// 	}
	
// 	return request.post(`/web/index.php`, data, {
// 		headers: {
// 			'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
// 			token:token,
// 			"Accept": "application/json, text/plain, */*",
// 			"Accept-Encoding": "gzip, deflate",
// 			"Accept-Language": "zh-CN,zh;q=0.9",
// 			"Connection": "keep-alive",
// 		}
// 	});
// }