<template>
	<div
		class="main swipper-content"
		v-loading="listLoading"
		element-loading-text="Loading.."
		element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.5)"
	>
		<div class="title">
			<div>
				<div class="f-s-30 VC-R title-s">
					{{ title }}
				</div>
				<div>{{ subTitle }}</div>
			</div>
			<div v-if="showMore" class="t-d-u c-p" @click="toClass">See All</div>
		</div>
		<div v-if="isMobile" class="isMobile">
			<div class="item-card c-p">
				<div
					class="item-box"
					v-for="(i, index) in productListAll"
					@click="goDetails(i.id)"
					:key="index"
				>
					<img style="object-fit: cover" :src="i.pic_url" alt=""  class="product-image-scale product-img"/>
					<div class="f-w-700 titles f-s-20 product-title-scale">{{ i.name }}</div>
					<div class="sub-til" v-if="token">
						${{ $parseFloat(i.unitPrice, 2) }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MSRP ${{ i.MSRP }}
					</div>
					<div class="sub-til" v-if="!token">
						<div class="pad-b-10">MSRP ${{ $parseFloat(i.MSRP, 2) }}</div>
						<div style="display: flex; align-items: center;">
							<img src="../assets/image/lock.png" alt="" />
							<div style="margin-left: 10px" class="msrp-text pad-r-10">Sign up for wholesale price</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="flex al-item relative juc-sb" v-else-if="moren == 'moren'">
			<div
				class="absolute z-index-100 c-p"
				@click="arrowClick('prev')"
				style="top: 50%; transform: translateY(-50%); left: -59px"
			>
				<i class="el-icon-arrow-left f-s-34" style="color: #000"></i>
			</div>
			<div class="product-content">
				<el-carousel
					height="500"
					class="carou-sel"
					arrow="never"
					:loop="false"
					:autoplay="false"
					indicator-position="none"
					ref="cardShow"
				>
					<el-carousel-item
						v-for="(inx, inxIndex) in pages"
						:key="inxIndex + 100"
					>
						<div class="item-card c-p">
							<div
								v-for="(i, index) in productList[inxIndex]"
								@click="goDetails(i.id)"
								:key="index"
							>
							<div class="item-box-desktop" :style="index !== 0 ? 'margin-left: 8px;': ''">
								<img style="object-fit: cover" :src="i.pic_url" alt="" class="product-image-scale product-img"/>
								<div class="f-w-700 titles f-s-20 product-title-scale">{{ i.name }}</div>
								<div class="sub-til" v-if="token">
									${{ $parseFloat(i.unitPrice, 2) }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MSRP ${{ i.MSRP }}
								</div>
								<div class="sub-til" v-if="!token">
									<div class="pad-b-10">MSRP ${{ i.MSRP }}</div>
									<div style="display: flex; align-items: center;">
										<img src="../assets/image/lock.png" alt="" />
                						<div style="margin-left: 10px" class="msrp-text pad-r-10">Sign up for wholesale price</div>
									</div>
								</div>
							</div>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div
				class="absolute z-index-100 c-p"
				@click="arrowClick('next')"
				style="top: 50%; transform: translateY(-50%); right: -59px"
			>
				<i class="el-icon-arrow-right f-s-34" style="color: #000"></i>
			</div>
		</div>

		<div
			class="flex al-item relative juc-sb"
			v-if="moren == 'zidinyi' && !isMobile"
		>
			<div
				class="absolute z-index-100 c-p"
				@click="arrowClick('prev')"
				style="top: 50%; transform: translateY(-50%); left: -59px"
			>
				<i class="el-icon-arrow-left" style="color: #000; font-size: 35px"></i>
			</div>
			<div style="width: 100%">
				<el-carousel
					class="carou-sel"
					arrow="never"
					:loop="false"
					:autoplay="false"
					indicator-position="none"
					ref="cardShow"
				>
					<el-carousel-item v-for="inx in 2">
						<div class="item-cards c-p">
							<div v-for="i in 5" @click="goDetails">
								<img src="../assets/image/s-l1.png" alt="" />
								<div style="background: #dee5d3; padding: 9px 0">
									<div
										style="
											background-color: #dee5d3;
											margin-bottom: 9px;
											line-height: 19px;
										"
										class="f-w-700 titles f-s-20"
									>
										Black ball pen
									</div>
									<div
										style="
											background-color: #dee5d3;
											color: #4d4d4d;
											line-height: 19px;
										"
										class="sub-til PP-B t-d-u"
									>
										New Item!
									</div>
								</div>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div
				class="absolute z-index-100 c-p"
				@click="arrowClick('next')"
				style="top: 50%; transform: translateY(-50%); right: -59px"
			>
				<i class="el-icon-arrow-right" style="color: #000; font-size: 35px"></i>
			</div>
		</div>
	</div>
</template>

<script>
import { commonApi } from "@/api/common";
import { mapState } from "vuex";
export default {
	computed: {
		...mapState(["token"]),
	},
	props: {
		title: "",
		subTitle: "",
		compKey: "",
		moren: {
			type: String,
			default: "moren",
		},
		showMore: {
			type: Boolean,
			default: false,
		},
		mchId: {
			type: String,
			default: '86',
		},
		requestPath: {
			type: String,
			default: 'api/default/goods-list',
		},
	},
	data() {
		return {
			productListAll: [],
			productList: [],
			pages: 0,
			isMobile: false,
			total: 0,
			listLoading: false,
			showCount: 5
		};
	},
	methods: {
		handleResize() {
			// let flag = navigator.userAgent.match(
			// 	/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			// );
			let isMobileWidth = window.innerWidth < 800;
			this.isMobile = isMobileWidth;
		},
		toClass() {
			this.$router.push("/class");
		},
		calcPage() {},

		loadCache() {
			let key = this.compKey;
			let data = null;
			let mchId = this.mchId;
			try {
				let str = window.localStorage["shop-list-" + key + mchId];
				if (str) {
					this.total = window.localStorage["shop-list-total-" + key + mchId];
					data = JSON.parse(str);
				}
				if (data != null) {
					this.showData(data);
				}
			} catch (e) {}
		},
		showData(list) {
			// list.sort(() => Math.random() - 0.5);
			let showCount = this.showCount;
			let len = list.length;
			let pages = parseInt(len / showCount);
			if (len % showCount != 0) {
				pages++;
			}
			this.pages = pages;
			for (let i = 0; i < pages; i++) {
				let arr = [];
				for (let e = 0; e < showCount; e++) {
					let item = list[e + i * showCount];
					if (item) {
						// let MSRP = parseFloat(item.price * this.getRandomArbitrary(2.4,2.5));
						let unitPrice = parseFloat(item.price);
						if (item.container && parseFloat(item.container) > 0) {
							// MSRP = MSRP / parseFloat(item.container);
							unitPrice /= parseFloat(item.container);
						}
						item.unitPrice = unitPrice;
						item.MSRP = (unitPrice * 2).toFixed(2)
						arr.push(item);
						this.productListAll.push(item);
					}
				}
				this.productList.push(arr);
			}
			this.listLoading = false;
			// console.log("this.productList", this.productList);
		},
		async getProducts(page = 1) {
			let key = this.compKey;
			let mchId = this.mchId;
			if (!window.localStorage["shop-list-" + key + mchId]) {
				this.listLoading = true;
			}
			let param = {
				r: this.requestPath,
				page
			}
			if(this.mchId) {
				param.mch_id = mchId
			}
			commonApi(param).then((res) => {
				if (res && res.data && res.data.code == 0) {
					// this.productList = res.data.data.list;
					let list = res.data.data.list;
					window.localStorage["shop-list-" + key + mchId] = JSON.stringify(list);
					this.showData(list);
					window.localStorage["shop-list-total-" + key + mchId] =
						res.data.data.page_count;
					this.total = res.data.data.page_count;
				}
			});
		},
		arrowClick(val) {
			if (val === "next") {
				this.$refs.cardShow.next();
			} else {
				this.$refs.cardShow.prev();
			}
		},
		goDetails(id) {
			if (this.token) {
				this.$router.push("/details?id=" + id);
			} else {
				if (this.isMobile) {
					this.$router.push("/wavfloginMobile");
				} else {
					this.$router.push("/login");
				}
			}
		},
	},
	created() {
		this.loadCache();

		this.getProducts();
	},
	mounted() {
		window.addEventListener("resize", this.handleResize);
		this.handleResize(); // 初始设置窗口大小
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.handleResize);
	},
	getRandomArbitrary(min, max) {
		return Math.random() * (max - min) + min;
	},
};
</script>

<style lang="scss" scoped>
.main {
	height: fit-content;
	max-width: 1800px;
	@media (max-width: 800px) {
		width: 100% !important;
	}
	width: 80%;
	margin: 0 auto;
	overflow: visible !important;
	// border:1px solid red;

	.isMobile {
		width: 100vw;
		height: fit-content;
		padding: 10px;
		overflow-x: auto;
		overflow-y: hidden;
		.item-box {
			border: 1px solid #dfe0e1;
			margin: 0 5px;
		}
	}

	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 14px;

		.title-s {
			line-height: 35px;
		}
	}

	.carou-sel {
	}

	.item-card {
		.item-box-desktop {
			border: 1px solid #dfe0e1;
		}
		display: flex;
		justify-content: space-between;

		.product-img {
			border-bottom: 1px solid #dfe0e1;
			margin-bottom: 20px;
		}

		.titles {
			height: 42px;
			padding-left: 10px;
			padding-right: 10px;
			word-break: break-all;
			// white-space: nowrap;
			line-height: 32px;
			/* 设置宽度和高度 */
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			/* 设置宽度和高度 */

			/** 文本1 */
			font-size: 14px;
			font-weight: bold;
			letter-spacing: 0px;
			line-height: 19.6px;
			color: rgba(36, 40, 48, 1);
		}

		.sub-til {
			color: #4d4d4d;
			font-size: 14px;
			padding-left: 10px;
			border-top: 1px solid #dfe0e1;
			padding-top: 15px;
			padding-bottom: 15px;
			margin-top: 20px;
			word-break: break-all;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			.msrp-text {
				word-break: break-all;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
			}
		}

	}

	.item-cards {
		padding-bottom: 65px;
		display: flex;
		justify-content: space-between;
		text-align: center;


		.titles {
			display: flex;
			justify-content: center;
		}

		.sub-til {
			color: #4d4d4d;
			font-size: 14px;
		}
	}

	// ::v-deep .el-carousel__container {
	// 	height: 415px;
	// }
	::v-deep .el-carousel__item {
		width: fit-content !important;
	}
	@media (min-width: 801px) {
		::v-deep .el-carousel__container {
			height: calc(27vw);
			min-height: 330px;
			max-height: 450px;
		}
		.product-image-scale {
			width: calc((80vw - (4 * 8px) - 10px) / 5);// 11是 2(左右两个间隙) + 8(item左右为2) + 1(外部间隙)
			height: calc((80vw - (4 * 8px) - 10px) / 5);
			max-width: calc((1800px * 0.8 - (4 * 8px) - 10px) / 5);
			max-height: calc((1800px * 0.8 - (4 * 8px) - 10px) / 5);
		}
		.product-title-scale {
			width: calc((80vw - (4 * 8px) - 10px) / 5);
			max-width: calc((1800px * 0.8 - (4 * 8px) - 10px) / 5);
		}
		.sub-til {
			width: calc((80vw - (4 * 8px) - 10px) / 5);
			max-width: calc((1800px * 0.8 - (4 * 8px) - 10px) / 5);
		}
		.swipper-content {
			width: calc(80vw);
			max-width: calc((1800px * 0.8));
			margin: 0 auto;
		}
		.product-content {
			width: 100%;
			max-width: calc((1800px * 0.8));
			margin: 0 auto;
		}
	}
	@media (max-width: 800px) {
		::v-deep .el-carousel__container {
			height: 400px;
		}
		.product-image-scale {
			width: calc((100vw - 4px) / 3 - 24px);
			height: calc((100vw - 4px) / 3 - 24px);
			max-width: calc((100vw - 4px) / 3 - 24px);
		}
		.product-title-scale {
			width: calc((100vw - 4px) / 3 - 24px);
		}
		.sub-til {
			width: calc((100vw - 4px) / 3 - 24px);
		}
	}
}
</style>
