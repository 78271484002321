<template>
	<div>
		<el-carousel :autoplay="false" arrow="none" indicator-position="none">
			<!-- {{imageList}} -->
			<div style="overflow: hidden; height: inherit;">
				<el-carousel-item class="relative el-carousel-item-e" v-for="i in imageList[0]" :key="i">
					<img class="w-full h-full object-cover"
						:src="`https://mer.wavf.com/web/uploads/image/ed/ede311c0610cc8460fab13d111433a1de8659ac3.jpg`"
						alt="" />
					<div class="absolute inset-0 flex flex-col p-4 gap-4 max-w-md lg:left-20 swiper-content-box">
						<div class="swi-con VC-R desktop-block">Find Your Next Best Seller</div>
						<div class="swi-con VC-R mobile-block">Find Your Next Win</div>

						<div class="swi-button-box flex">
							<div class="swi-wavf bg-white/10 backdrop-blur">
								<img src="../../../assets/image/AddBookmark.png" alt="" />
								<div class="c-p VC-R" @click="goBusiness">Sell on WAVF</div>
							</div>
							<div class="swi-join">
								<img src="../../../assets/image/Join.png" alt="" />
								<div class="c-p VC-R" @click.stop="goWavfLogin">Join Today</div>
							</div>
						</div>
					</div>
				</el-carousel-item>
			</div>
		</el-carousel>
	</div>
</template>

<script>
// import { mapState } from "vuex";
import { commonApi } from "@/api/common";
export default {
	// computed: {
	// 	...mapState(["token"]),
	// },
	data() {
		return {
			imageList: [],
			merBaseUrl: process.env.VUE_APP_MER_BASE_URL
		};
	},
	methods: {
		async loadData() {
			const res = await commonApi({
				r: "api/default/carousel",
			});

			if (res?.data?.code === 0) {
				// alert(1)
				this.imageList = res.data.data;
			}
		},
		goBusiness() {
			// if (this.token == "") {
			//   this.$message({
			//     message: "Please log in",
			//     type: "error",
			//   });
			//   this.$router.push("/login");
			//   return;
			// }
			this.$router.push("/business-r");
		},
		goWavfLogin() {
			if (window.innerWidth < 800) {
				this.$router.push("/wavfloginMobile");
			} else {
				this.$router.push("/wavflogin");
			}
		},
	},
	created() {
		this.loadData();
	},
};
</script>

<style lang="scss" scoped>
.swiper-content-box {
	top: 50%;
	--tw-translate-y: -50%;
	transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));

	.swi-button-box {
		flex-direction: column;
	}

	.swiper-img {
		width: 100%;
		// height: 599px;
		// margin-left: 20px;
	}

	.swi-con {
		// top: 70px;
		// left: 122px;
		font-size: 48px;
		// width: 467px;
	}

	.swi-wavf {
		// top: 271px;
		// left: 122px;
		font-size: 20px;
		// width: 389px;
		border: 1px solid #000;
		// padding: 14px 69px 16px 33px;
		@apply py-3 px-8;
		border-radius: 50px;
		display: flex;
		align-items: center;

		img {
			width: 33px;
			height: 33px;
			margin-right: 52px;
		}
	}

	.swi-join {
		display: flex;
		align-items: center;
		// top: 343px;
		margin-top: 32px;
		// padding: 15px 69px 16px 33px;
		@apply py-3 px-8;
		background-color: #1a251b;
		border-radius: 50px;
		color: #fff;
		left: 122px;
		font-size: 20px;

		img {
			width: 33px;
			height: 33px;
			margin-right: 52px;
		}
	}
}

::v-deep .el-carousel__arrow {
	border-radius: 0px;
	height: 52px;
	width: 30px;
	background-color: rgba(255, 255, 255, 0.3);
}

::v-deep .el-icon-arrow-left:before {
	font-size: 30px;
	color: #000;
}

::v-deep .el-icon-arrow-right:before {
	font-size: 30px;
	color: #000;
}

::v-deep .el-carousel__button {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #000;
}

::v-depp .el-carousel--horizontal::-webkit-scrollbar {
	display: none;
}

::v-deep .el-carousel__container::-webkit-scrollbar {
	display: none;
}

::v-deep .el-carousel--horizontal {
	overflow-x: visible !important;
}

@media (min-width: 801px) {
	.mobile-block {
		display: none;
	}

	.desktop-block {
		display: block;
	}

	::v-deep .el-carousel__container {
		height: 599px;
		overflow: hidden !important;
	}

	::v-deep [data-v-adfa2fbc] .el-carousel__container {
		height: 599px;
		overflow: hidden !important;
	}

}

@media (max-width: 800px) {
	.mobile-block {
		display: block;
	}

	.desktop-block {
		display: none;
	}

	::v-deep .el-carousel__container {
		height: calc(40vw);
		overflow: hidden !important;
	}

	::v-deep [data-v-adfa2fbc] .el-carousel__container {
		height: calc(40vw);
		overflow: hidden !important;
	}

	.swiper-content-box {
		height: 80%;
		justify-content: space-between;
		top: 10%;
		--tw-translate-y: 0%;
		transform: none;
		max-width: 95%;
		.swi-button-box {
			flex-direction: row;

			.swi-wavf {
				padding: 0 0 0 3.125vw;
				height: 7.69vw;
				font-size: 3.08vw;
				margin-left: 24px;
				width: 45%;
			}

			.swi-join {
				padding: 0 3.125vw;
				height: 7.69vw;
				font-size: 3.08vw;
				margin-left: 32px;
				margin-top: 0px;
				width: 45%;
			}

			img {
				width: 20px;
				height: 20px;
				margin-right: 2.051vw;
			}

		}

		.swi-con {
			font-size: 5.6vw;
			margin-left: 24px;
			width: 30vw;
		}
	}
}

</style>
