import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "./common/base.css";
import "./common/common.css";
import "./common/public.css";
import "./assets/main.css";
import "element-ui/lib/theme-chalk/index.css";
import "@/font/css/font.css";
import "swiper/css";
import "swiper/css/pagination";
import locale from "element-ui/lib/locale/lang/en"; // lang i18n

import VueGtag from "vue-gtag";
import { get, post } from "@/api/common";

// import "@/utils/use_translate";

Vue.use(ElementUI, {
	locale,
});
// import 'amfe-flexible'

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.getRandomArbitrary = function (min, max) {
	return Math.random() * (max - min) + min;
};
Vue.prototype.$parseFloat = function (num, point) {
	return parseFloat(num).toFixed(point ? point : 0);
};

Vue.prototype.showError = function (msg) {
	this.$message({
		type: "error",
		message: msg,
		offset: 200,
	});
};
Vue.prototype.showSucc = function (msg) {
	this.$message({
		type: "success",
		message: msg,
		offset: 200,
	});
};

Vue.use(VueGtag, {
	config: { id: process.env.VUE_APP_GA_KEY },
	// appName: `WAVF-${process.env.VUE_APP_ENV}`,
}, router);

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
