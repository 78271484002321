<template>
	<div class="contents" style="padding-top:10px;">
		<!-- <div style="text-align: right;cursor: pointer;">
							<i style="position: relative;font-size: 30px;top:-40px;" class="el-icon-close"
								@click.stop="goBack"></i>
						</div>
						<div class="f-s-40 tx-cen">Sign in</div> -->
		<div class="f-s-16 tx-cen" style="color: #666; margin: 10px 0 10px 0">
			New to WAVF?
			<span class="t-d-u c-p" style="color: #000" @click="goWavfLogin">Sign up to shop</span>
		</div>
		<div class="f-s-16" style="margin: 5px 0; color: #666">
			Email address
		</div>
		<el-input v-model="email"></el-input>
		<div class="flex juc-sb al-item f-s-16" style="margin: 10px 0 5px; color: #666">
			<div>Password</div>
			<!-- <div class="c-p flex al-item">
	    <img
	      src="../../assets/image/hide.png"
	      style="width: 18px; height: 16px"
	      alt=""
	    />
	    <div class="mag-l-10">Hide</div>
	  </div> -->
		</div>
		<el-input show-password v-model="password" type="password"></el-input>
		<div class="f-s-16 t-d-u c-p" style="margin: 10px 0 0" @click="goForgetPassword">
			Forget password?
		</div>
		<div @click="submit" class="tx-cen c-p bod-ra-50px" style="
	    background-color: #406055;
	    width: 100%;
	
	    padding: 12.5px 0 13.5px;
	    margin: 16px 0;
	    color: #fff;
	  ">
			Log in
		</div>

		<div class="flex al-item juc-sb mag-t-10">

			<div style="
	      border: 1px solid #000;
	      width: 100%;
	      padding: 10px 30px;
	      display: flex;
	      align-items: center;
	      justify-content: center;
	      margin: 0 auto;
	    ">
				<img src="../assets/image/Socialmedia.png" alt="Google Login" style="margin-right: 10px" />
				<p style="margin: 0">Sign in With Google</p>
			</div>
		</div>

		<div class="f-s-20" style="
	    border: 1px solid #000;
	    padding: 10px 0;
	    text-align: center;
	    margin: 16px 0;
	  ">
			Are you a brand?
			<span class="t-d-u c-p" style="color: #406055" @click="goBuniessLogin">Sign up to sell</span>
		</div>
		<div style="color: #666">
			By signing in you agree to our
			<span class="t-d-u c-p" @click="centerDialogVisible = true">Terms of Use</span>
			and
			<span class="t-d-u c-p" @click="centerDialogVisible = true">Privacy Policy</span>
		</div>
	</div>
</template>

<script>
	import {
		commonApi
	} from "@/api/common";
	export default {
		components: {},
		data() {
			return {
				centerDialogVisible: false,
				email: "",
				password: "",
			};
		},
		mounted() {
			// this.getPost();
		},
		methods: {
			goBack() {
				this.$router.go(-1);
			},
			async submit() {
				if (this.email == "") {
					return this.$message({
						message: "Please fill in email address",
						type: "error",
					});
				}
				if (this.password == "") {
					return this.$message({
						message: "Please fill in  password",
						type: "error",
					});
				}

				let obj = {
					r: "api/passport/login",
					email: this.email,
					password: this.password,
				};
				const res = await commonApi(obj);
				if (res.data.code == 0) {
					this.$message({
						message: res.data.msg,
						type: "success",
					});
					localStorage.setItem("token", res.data.data.access_token);
					localStorage.setItem("tokenExp", res.data.data.access_token);

					localStorage.setItem("nickname", res.data.data.nickname);
					this.$store.commit("setToekn", res.data.data.access_token);
					this.$store.commit("setNickName", res.data.data.nickname);
					const red = await commonApi({
						r: "api/user/oauth"
					});
					if (red.data.code == 0) {
						if (res.data.data.mch_id) {
							window.location.href = red.data.data;
						}
					} else {
						location.reload();
					}
				} else {
					this.$message({
						message: res.data.msg,
						type: "error",
					});
				}
			},
			goWavfLogin() {
				this.$router.push("/wavflogin");
			},
			goBuniessLogin() {
				this.$router.push("/business-r");
			},
			goLogin() {
				this.$router.push("/business-r");
			},
			goForgetPassword(){
				this.$router.push("/password/forget");
			}
		},
	};
</script>

<style lang="scss" scoped>
	.contents {
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 69px 59px 35px;
		border-radius: 10px;
		background-color: #fff;
	}
</style>