var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('Header',{directives:[{name:"show",rawName:"v-show",value:(_vm.page),expression:"page"}],on:{"catListLoaded":_vm.catListLoaded}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.page),expression:"page"}]},[(!_vm.token)?[_c('Swiper')]:_vm._e(),(_vm.token)?[_c('Shops',{attrs:{"catList":_vm.catList}})]:_vm._e(),(!_vm.token)?[_vm._m(0)]:_vm._e(),_c('shop-list',{staticStyle:{"margin-top":"32px"},attrs:{"compKey":"Featured","title":"Featured","requestPath":"api/default/goods-feature","mchId":""}}),(_vm.token)?[_c('shop-list',{staticStyle:{"margin-top":"129px"},attrs:{"compKey":"NewArrival","title":"New Arrival","mchId":"","requestPath":"api/default/goods-feature"}})]:_vm._e(),_c('div',[_c('CatStatic')],1),(!_vm.token)?[_c('Middles')]:_vm._e(),(_vm.token)?[_c('shop-list',{staticStyle:{"margin-top":"129px"},attrs:{"compKey":"TopRanking","title":"Top Ranking","mchId":"","requestPath":"api/default/goods-top-rank"}})]:_vm._e(),(!_vm.token)?[_c('shop-list',{attrs:{"compKey":"NewArrival","title":"New Arrival"}})]:_vm._e(),(!_vm.token)?[_vm._m(1),_vm._m(2)]:_vm._e(),(_vm.token)?[_c('div',{staticClass:"missions"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"f-s-30 VC-R",style:(_vm.isMobile()
									? 'text-align:center'
									: 'margin-left: 67px; line-height: 43px')},[_vm._v(" You've got 50% off your first order! ")]),_c('div',{staticClass:"f-s-22 PP-R tx-cen"}),_c('div',{staticClass:"f-s-20",style:(_vm.isMobile()
									? 'text-align:center'
									: 'margin: 22px 0 22px 67px; width: 380px')},[_vm._v(" Welcome to WAVF, get 50% off your first order up to $150 ")]),_c('div',{style:(_vm.isMobile()
									? 'text-align:center'
									: 'width: 480px; margin-left: 67px')},[_vm._v(" offer expires in 2 weeks. ")])]),_c('div',{staticClass:"right",staticStyle:{"position":"relative","left":"-150px","top":"43px"}},[_c('el-image',{staticStyle:{"width":"140%","height":"374px"},attrs:{"src":_vm.src,"fit":"cover"}})],1)])]:_vm._e(),(!_vm.token)?[_c('div',{staticClass:"fooder !hidden lg:!flex"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"VC-R",staticStyle:{"font-size":"52px","margin":"0 128px 58px 0"}},[_vm._v(" Register Today ")]),_c('div',{staticClass:"PP-R c-p flex",staticStyle:{"background-color":"#000","border-radius":"50px","padding":"12px 64px 12px 65px","margin-right":"128px"},on:{"click":_vm.goWavfLogin}},[_c('div',[_vm._v("Sign Up to Shop")])])]),_vm._m(3)]),_c('div',{staticClass:"lg:hidden relative w-full flex flex-col justify-center px-4 min-h-60 mt-32"},[_c('div',{staticClass:"text-5xl text-white mb-5"},[_vm._v("Register Today")]),_c('div',{staticClass:"PP-R c-p flex text-white w-full justify-center",staticStyle:{"background-color":"#000","border-radius":"50px","padding":"12px 64px 12px 65px","margin-right":"128px"},on:{"click":_vm.goWavfLogin}},[_vm._v(" Sign Up to Shop ")]),_vm._m(4)])]:_vm._e(),_c('Fooder'),_c('el-backtop',{attrs:{"visibility-height":"200","target":".page-component__scroll .el-scrollbar__wrap"}}),_c('div',{staticStyle:{"position":"fixed","background-color":"#406055","width":"35px","display":"flex","height":"35px","right":"20px","justify-content":"center","align-items":"center","z-index":"10000","top":"620px"},on:{"click":_vm.goTop}},[_c('img',{staticStyle:{"width":"30px","height":"18px"},attrs:{"src":require("../../assets/image/Vector.png"),"alt":""}})])],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rememnd flex-row"},[_c('div',{staticClass:"item-card tx-cen"},[_c('img',{attrs:{"src":require("../../assets/image/CardPayment.png"),"alt":""}}),_c('div',{staticClass:"title VC-R"},[_vm._v("Flexible Payment")]),_c('div',{staticClass:"f-s-14 content"},[_vm._v("Buy Now, Pay up to 60 days later")]),_c('div',{staticClass:"f-s-14 content"},[_vm._v("Interest Free")])]),_c('div',{staticClass:"item-card tx-cen"},[_c('img',{attrs:{"src":require("../../assets/image/GetRevenue.png"),"alt":""}}),_c('div',{staticClass:"title VC-R"},[_vm._v("Boost your revenue")]),_c('div',{staticClass:"f-s-14 content desktop-content"},[_vm._v(" Best selling products to Boost Your revenue ")]),_c('div',{staticClass:"f-s-14 content"},[_vm._v(" All products and brands sold have a proven track record to help your store thrive ")])]),_c('div',{staticClass:"item-card tx-cen"},[_c('img',{attrs:{"src":require("../../assets/image/Transaction.png"),"alt":""}}),_c('div',{staticClass:"title VC-R"},[_vm._v("Free Return")]),_c('div',{staticClass:"f-s-14 content"},[_vm._v("Free returns on opening order")]),_c('div',{staticClass:"f-s-14 content"},[_vm._v(" Risk Free trails on all new Products and brands, ")]),_c('div',{staticClass:"f-s-14 content desktop-content"},[_vm._v("Discover your next best seller")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mission !hidden lg:!flex"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"f-s-52 VC-R",staticStyle:{"padding":"0 10px"}},[_vm._v("Mission")]),_c('p',{staticClass:"f-s-22 PP-R",staticStyle:{"width":"75%","padding":"25px 0 0 36px","line-height":"30px"}},[_vm._v(" Our mission is to empower small business owners by providing access to a diverse range of high-quality products sourced from around the globe, all offered at competitive prices. ")]),_c('p',{staticClass:"f-s-22 PP-R",staticStyle:{"width":"75%","height":"263px","padding":"0 0 45px 36px","line-height":"30px"}},[_vm._v(" Through our commitment to delivering excellence, we aim to be the trusted partner that small businesses rely on for sourcing innovative and reliable products, ultimately contributing to their success and prosperity. ")])]),_c('div',{staticClass:"right",staticStyle:{"position":"absolute","right":"0px","top":"92px","width":"55%"}},[_c('img',{attrs:{"src":require("../../assets/image/glob2.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mission-bg flex flex-col px-4 py-6 lg:hidden my-[150px] text-white",staticStyle:{"background":"linear-gradient("}},[_c('div',{staticClass:"f-s-48 VC-R"},[_vm._v("Mission")]),_c('p',{staticClass:"f-s-20 PP-R"},[_vm._v(" Our mission is to empower small business owners by providing access to a diverse range of high-quality products sourced from around the globe, all offered at competitive prices. ")]),_c('p',{staticClass:"f-s-20 PP-R"},[_vm._v(" Through our commitment to delivering excellence, we aim to be the trusted partner that small businesses rely on for sourcing innovative and reliable products, ultimately contributing to their success and prosperity. ")]),_c('div',{staticClass:"t-d-u f-s-22 c-p PP-R"},[_vm._v("Click to learn more")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("../../assets/image/index_footer.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"absolute inset-0 -z-10"},[_c('img',{staticClass:"w-full h-full object-cover",attrs:{"src":require("../../assets/image/index_footer.png"),"alt":""}})])
}]

export { render, staticRenderFns }