<template>
  <div style="position: relative; margin-top: 206px">
    <img
      :style="isMobile ? 'object-fit: cover;' : ''"
      style="width: 100%; height: 662px"
      src="https://img.js.design/assets/img/6604ec91446df72277d82ccf.png#9a811213101d706353cde3850f4e55b1"
      alt=""
    />
    <div class="sell-wavf">
      <div class="VC-R title" style="">Sell on WAVF</div>
      <div class="PP-R">A global marketplace for small business</div>
      <div class="PP-R" style="margin: 12px 0">
        Curated selection of products
      </div>
      <div class="PP-R">
        Highest standards of quality,<br v-if="isMobile" />
        affordability, and relevance
      </div>
      <div
        class="c-p bod-ra-50px PP-R"
        style="
          background-color: #000;
          padding: 12px 77px 12px 78px;
          margin-top: 38px;
        "
      >
        Learn About Selling
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize(); // 初始设置窗口大小
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 1000;
    },
  },
};
</script>

<style lang="scss" scoped>
.sell-wavf {
  color: #fff;
  height: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 462px;
  background-color: rgba(0, 0, 0, 0.56);
  display: flex;
  font-size: 22px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(64, 96, 85, 0.9) 0%,
    rgba(166, 195, 174, 0.9) 100%
  );
  @media (max-width: 1000px) {
    font-size: 18px !important;
  }
  .title {
    font-size: 52px;
    margin-bottom: 38px;
    color: #fff;
    @media (max-width: 1000px) {
      font-size: 42px !important;
      margin-bottom: 28px !important;
    }
  }
}
</style>
