<template>
	<div class="main">
		<div class="content product-content">
			<div class="title">
				<div
					:style="
						isMobile ? 'text-align:center;width:100vw;margin-left:-10px' : ''
					"
				>
					<div
						:class="isMobile ? 'f-s-32' : 'f-s-52'"
						style="font-family: 'Vc-Black'; line-height: 60px; color: #fff"
					>
						Welcome back,<br v-if="isMobile" />
						{{ nickname }}
					</div>
					<div style="color: #fff; font-size: 20px; line-height: 28px">
						Categories for you
					</div>
				</div>
			</div>
			<div v-if="isMobile" class="isMobile">
				<div class="item-card c-p">
					<div
						class="itemBox"
						v-for="(item, index) in [...dataList, ...deepList]"
						@click="goClass(item)"
					>
					<img style="object-fit: cover" :src="item.pic_url" alt="" class="product-image-scale"/>

						<!-- <el-image
							style="object-fit: cover"
							:src="i.pic_url"
							lazy
						></el-image> -->
						<div class="f-w-700 titles PP-B product-title-scale">{{ item.name }}</div>
					</div>
				</div>
			</div>
			<div class="flex al-item relative juc-sb" v-else-if="moren == 'moren'">
				<div
					class="absolute z-index-100 c-p"
					@click="arrowClick('prev')"
					style="top: 50%; transform: translateY(-50%); left: -59px"
				>
					<i
						class="el-icon-arrow-left"
						style="color: #fff; font-size: 35px"
					></i>
				</div>
				<div style="width: 100%;">
					<el-carousel
						height="310px"
						arrow="never"
						:loop="false"
						:autoplay="false"
						indicator-position="none"
						ref="cardShow"
					>
						<el-carousel-item>
							<div class="item-card c-p">
								<div
									v-for="(item, index) in dataList"
									v-if="index < 5"
									style="margin: 8px;"
									@click="goClass(item)"
								>
									<img style="object-fit: cover" :src="item.pic_url" alt="" class="product-image-scale"/>
									<!-- <el-image
										style="object-fit: cover"
										:src="i.pic_url"
										lazy
									></el-image> -->

									<div class="f-w-700 titles PP-B product-title-scale">{{ item.name }}</div>
								</div>
							</div>
						</el-carousel-item>
						<el-carousel-item>
							<div class="item-card c-p">
								<div
									v-for="(item, index) in deepList"
									v-if="index < 5"
									style="margin: 8px;"
									@click="goClass(item)"
								>
									<img style="object-fit: cover" :src="item.pic_url" alt="" class="product-image-scale"/>
									<!-- <el-image
										style="object-fit: cover"
										:src="i.pic_url"
										lazy
									></el-image> -->

									<div class="f-w-700 titles PP-B">{{ item.name }}</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
				<div
					class="absolute z-index-100 c-p"
					@click="arrowClick('next')"
					style="top: 50%; transform: translateY(-50%); right: -59px"
				>
					<i
						class="el-icon-arrow-right"
						style="color: #fff; font-size: 35px"
					></i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	props: {
		catList: {
			type: [Array, Object],
			default: [],
		},
		title: "",
		subTitle: "",
		moren: {
			type: String,
			default: "moren",
		},
	},
	computed: {
		...mapState(["nickname"]),
		...mapState(["token"]),
	},
	watch: {
		catList(val) {
			this.dataList = val;
			let list = val;
			let deepList = [];
			for (let i = 0; i < list.length; i++) {
				let curr = list[i];
				for (let a = 0; a < curr.list.length; a++) {
					let currItem = curr.list[a];
					if (currItem.pic_url) {
						deepList.push(currItem);
						if (deepList.length >= 5) {
							break;
						}
					}
				}
				// deepList = deepList.concat(curr.list);
			}
			this.deepList = deepList;
		},
	},
	data() {
		return {
			isMobile: false,
			dataList: [],
			deepList: [],
		};
	},
	methods: {
		handleResize() {
			this.isMobile = window.innerWidth < 800;
		},
		arrowClick(val) {
			if (val === "next") {
				this.$refs.cardShow.next();
			} else {
				this.$refs.cardShow.prev();
			}
		},
		goClass(item) {
			localStorage.setItem("catItem", JSON.stringify(item));
			// console.log(this.$route.path)
			// this.$router.push("/class?dataSrc=cache");
			this.$router.push({
				path: "/class",
				query: {
					dataSrc: "cache",
					name: item.name,
				},
			});
			this.$emit("reInitCacheCat");
		},
	},
	created() {
		this.dataList = this.catList;
		let list = this.catList;
		let deepList = [];
		for (let i = 0; i < list.length; i++) {
			let curr = list[i];
			for (let a = 0; a < curr.list.length; a++) {
				let currItem = curr.list[a];
				if (currItem.pic_url) {
					deepList.push(currItem);
					if (deepList.length >= 5) {
						break;
					}
				}
			}
			// deepList = deepList.concat(curr.list);
		}
		this.deepList = deepList;
		// console.log(
		// 	"wqrqwrqwrqwrqwr*************************************************************",
		// 	deepList
		// );
	},
	mounted() {
		window.addEventListener("resize", this.handleResize);
		this.handleResize(); // 初始设置窗口大小
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.handleResize);
	},
};
</script>

<style lang="scss" scoped>
.main {
	height: 494px;
	background: linear-gradient(
		0deg,
		rgba(64, 96, 85, 1) 0%,
		rgba(144, 176, 165, 1) 100%
	);
	overflow: visible !important;
	opacity: 1;
	padding: 19px 0 38px;
	.isMobile {
		width: 100vw;
		height: 280px;
		overflow-x: auto;
		overflow-y: hidden;
		.itemBox {
			flex: 0 0 140px;
			margin: 10px;
		}
	}
	.content {
		width: calc(80% + 16px);
		margin: 0 auto;
		.title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 14px;
		}
		.item-card {
			color: #fff;
			padding-bottom: 65px;
			display: flex;
			height: 400px;
			align-items: center;
			.titles {
				margin: 13px 0;
			}
			.sub-til {
				color: #4d4d4d;
			}
		}

		.item-cards {
			padding-bottom: 65px;
			display: flex;
			text-align: center;
			// padding: 9px 0;
			img {
				width: 247px;
				height: 50px;
			}
			.titles {
				display: flex;
				justify-content: center;
			}
			.sub-til {
				color: #4d4d4d;
			}
		}
	}
	@media (min-width: 801px) {
		.product-image-scale {
			width: calc((80vw - (10 * 8px)) / 5);
			height: calc((80vw - (10 * 8px)) / 5);
			max-width: calc((1800px * 0.8 - (10 * 8px) - 32px) / 5);
			max-height: calc((1800px * 0.8 - (10 * 8px) - 32px) / 5);
		}
		.product-title-scale {
			width: calc((80vw - (10 * 8px)) / 5);
			max-width: calc((1800px * 0.8 - (10 * 8px) - 64px) / 5);
			white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
		}
		.product-content {
			width: calc(80vw);
			max-width: calc((1800px * 0.8));
			margin: 0 auto;
		}
	}
	@media (max-width: 800px) {
		.product-image-scale {
			width: 140px;
			height: 170px;
		}
		.product-title-scale {
			width: 140px;
			white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
		}
	}
}
</style>
