import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "",
    nickname: localStorage.getItem("nickname")
      ? localStorage.getItem("nickname")
      : "",
    userinfo: localStorage.getItem("userinfo")
      ? sessionStolocalStoragerage.getItem("userinfo")
      : "",
    showLogin: true,
    exchangeRateCAD: sessionStorage.getItem("exchangeRateCAD")
        ? sessionStorage.getItem("exchangeRateCAD")
        : 1.38
  },
  getters: {},
  mutations: {
    setToekn(state, newToken) {
      state.token = newToken;
    },
    setNickName(state, newNickName) {
      state.nickname = newNickName;
    },
    setUserInfo(state, newUserinfo) {
      state.userinfo = newUserinfo;
    },
    setExchangeRateCAD(state, newExchangeRateCAD) {
      state.exchangeRateCAD = newExchangeRateCAD;
    },
  },
  actions: {},
  modules: {},
});
