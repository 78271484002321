<template>
  <div>
    <div
      class="c-p"
      style="position: fixed; top: 20px; left: 20px"
      @click="goHome"
    >
      <img
        style="width: 159px; height: 32px"
        src="../assets/image/LOGO.svg"
        alt=""
      />
    </div>
    <div
      class="c-p"
      style="position: fixed; top: 20px; right: 5%"
      @click="goBack"
    >
      <img
        style="width: 50px; height: 50px"
        src="../assets/image/Close.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goHome() {
      this.$router.push("/home");
    },
    goBack() {
      this.$store.state.showLogin = false;
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
