<template>
  <div class="main">
    <div class="right">
      <div class="content">
        <div
          class="flex juc-center"
          style="font-size: 20px; text-align: center"
        >
          <img
            style="width: 159px; height: 32px"
            src="../../assets/Logo.png"
            alt=""
          />
        </div>
        <div class="f-s-20 tx-cen" style="margin: 8px 0; line-height: 35px">
          Find Your Next Win
        </div>
        <div style="text-align: center; color: #666">
          Shop over 1000+ brands
        </div>
        <div
          style="
            margin: 39px 0 7px;
            line-height: 22px;
            color: #666;
            font-size: 16px;
          "
        >
          Business email
        </div>
        <el-input v-model="input1"></el-input>

        <div
          @click="goRegister"
          class="f-s-20 c-p"
          style="
            transform: scale(0.7);
            margin-top: 16px;
            background-color: #406055;
            width: 100%;
            border-radius: 50px;
            text-align: center;
            padding: 13px 0;
            color: #fff;
          "
        >
        Sign up for free
        </div>

        <div
          style="
            height: 21px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0 0;
          "
        >
          <div
            style="height: 1px; width: 150px; background-color: #d9d9d9"
          ></div>
          <div style="color: #d9d9d9">or</div>
          <div
            style="height: 1px; width: 150px; background-color: #d9d9d9"
          ></div>
        </div>

        <!-- <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
          "
        >
          <div
            style="
              border: 1px solid #000;
              padding: 5px 20px;
              border-radius: 50px;
            "
          >
            <div
              style="
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background-color: #0c82ee;
              "
            >
              <img src="../../assets/image/f.png" alt="" />
            </div>
          </div>
          <div
            style="
              border: 1px solid #000;
              padding: 5px 20px;
              border-radius: 50px;
            "
          >
            <div
              style="
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background-color: #000;
              "
            >
              <img src="../../assets/image/apply.png" alt="" />
            </div>
          </div>
          <div
            style="
              border: 1px solid #000;
              padding: 5px 20px;
              border-radius: 50px;
            "
          >
            <div
              style="
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
              "
            >
              <img src="../../assets/image/Sociallogo.png" alt="" />
            </div>
          </div>
          <div
            style="
              border: 1px solid #000;
              padding: 5px 20px;
              border-radius: 50px;
            "
          >
            <div
              style="
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
              "
            >
              <img src="../../assets/image/Socialmedia.png" alt="" />
            </div>
          </div>
        </div> -->

        <div
          style="
            background-color: #eef2e9;
            padding: 5px 0;
            border-radius: 5px;
            text-align: center;
            margin: 20px 0 10px;
          "
        >
          Already have an account?
          <span @click="goLogin" class="t-d-u c-p" style="color: #406055"
            >Sign in</span
          >
        </div>

        <div
          style="
            background-color: #eef2e9;
            padding: 5px 0;
            border-radius: 5px;
            text-align: center;
          "
        >
          Are you a brand?
          <span class="t-d-u c-p" @click="goRegisterB" style="color: #406055"
            >Sign up to sell on WAVF</span
          >
        </div>
      </div>
    </div>
    <TopHeader></TopHeader>
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader.vue";

export default {
  components: {
    TopHeader,
  },
  data() {
    return {
      input1: "",
      input2: "",
    };
  },
  mounted() {},
  methods: {
    goRegisterB() {
      this.$router.push("/business-r");
    },
    goRegister() {
      let email = this.input1;
      // validator.js

      let emailRegExp = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!emailRegExp.test(email)) {
        this.$message({
          message: "Please enter the correct email address",
          type: "error",
        });
        return;
      }
      this.$router.push("/register?email=" + email);
    },
    goLogin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  .right {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(
      -15.3deg,
      rgba(64, 96, 85, 1) 0%,
      rgba(222, 229, 211, 1) 100%
    );
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 90%;
      padding: 40px 30px;
      border-radius: 10px;
      background-color: #fff;
    }
    ::v-deep .el-input__inner {
      height: 36px;
    }
  }
}
</style>
