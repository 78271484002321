<template>
  <div class="category">
    <div class="title VC-R">Category</div>
    <div class="category-list">
      <div
        class="item"
        @click="goClass(e)"
        v-for="(i, e) in dataList"
        v-if="e < 5"
      >
        <!-- {{i}} -->
        <img
          class="img-list img-row"
          style="object-fit: cover"
          :src="i.pic_url"
          alt=""
        />
        <div class="white-h">
          <div
            class="absolute f-s-30 PP-R"
            style="left: 50%; top: 50%; transform: translate(-50%, -50%)"
          >
            {{ i.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="cate" v-if="!isMobile">
      <div class="left" @click="goClass(dataList[5])">
        <img style="object-fit: cover" class="left-img" :src="dataList[5].pic_url" alt="" />

        <div class="white-h PP-R">
          <div
            class="absolute f-s-30 flex juc-center fd-cloum al-item"
            style="left: 50%; top: 50%; transform: translate(-50%, -50%)"
          >
            {{ dataList[5].name }}
            <!-- <div style="margin: 30px 0">200% OF</div>
						<div>FEnds in 364D 23h59m59s</div> -->
          </div>
        </div>
      </div>
      <div class="right">
        <div
          class="item"
          v-for="(i, e) in dataList"
          v-if="e > 5"
          @click="goClass(e + 5)"
        >
          <img style="object-fit: cover" class="img-right" :src="i.pic_url" alt="" />
          <div class="white-h">
            <div
              class="absolute f-s-30 PP-R"
              style="left: 50%; top: 50%; transform: translate(-50%, -50%)"
            >
            {{ i.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="isMobile">
        <div class="box1">
          <div
            class="item"
            v-for="(i, e) in dataList"
            v-if="e >= 6"
            @click="goClass(e + 6)"
          >
            <img style="object-fit: cover" :src="i.pic_url" alt="" />
          </div>
        </div>
        <div class="box1">
          <div class="left" @click="goClass(dataList[5])">
            <img
              style="object-fit: cover; height: 200px"
              width="100%"
              :src="dataList[5].pic_url"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { commonApi } from "@/api/common";
let loadCount = 0;
export default {
  data() {
    return {
      isMobile: false,
      list: [
        require("@/assets/image/sl2.png"),
        require("@/assets/image/sl3.png"),
        require("@/assets/image/sl4.png"),
        require("@/assets/image/sl5.png"),
        require("@/assets/image/s-l1.png"),
      ],
      dataList: [{}, {}, {}, {}, {}, {}, {}, {}],
    };
  },
  async created() {
    let idArr = [
      180, 217, 201, 220, 183, 218, 178, 196,
      // 180,186,201,220,183,218,197,196
    ];
	this.loadDataByIdList(idArr);
    // let dataList = [{}, {}, {}, {}, {}, {}, {}, {}];
    // for (let i = 0; i < idArr.length; i++) {
    //   this.loadDataById(idArr[i], (item) => {
    //     // this.dataList[i] = item;
    //     dataList[i] = item;
    //     loadCount++;
    //     if (loadCount == 8) {
    //       setTimeout(() => {
    //         console.log("dataListdataListdataListdataListdataList", dataList);
    //         this.dataList = dataList;
    //       }, 200);
    //     }
    //   });

    //   // dataList.push(await this.loadDataByIdAsync(idArr[i]));
    // }
    // this.dataList = dataList;
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize(); // 初始设置窗口大小
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 1000;
    },
	loadDataByIdList(arr){
		commonApi({
		  r: "api/default/cat-list",
		  cat_id_list: arr.toString(),
		}).then((res) => {
			// alert(res)
		  if (res.data.code == 0) {
			  this.dataList = res.data.data.list;
		  } else {
		  }
		});
	},
    loadDataById(id, callback) {
      commonApi({
        r: "api/default/cat-detail",
        id: id,
      }).then((res) => {
        if (res.data.code == 0) {
          if (typeof callback == "function") {
            callback(res.data.data);
          }
        } else {
          callback({});
        }
      });
    },
    async loadDataByIdAsync(id) {
      let res = await commonApi({
        r: "api/default/cat-detail",
        id: id,
      });
      if (res.data.code == 0) {
        return res.data.data;
      } else {
        return {};
      }
    },
    goClass(item) {
      // alert(e);

      localStorage.setItem("catItem", JSON.stringify(item));
      // console.log(this.$route.path)
      // this.$router.push("/class?dataSrc=cache");
      this.$router.push({
        path: "/class",
        query: {
          dataSrc: "cache",
          name: item.name,
        },
      });
      // this.$router.push("/class");
    },
  },
};
</script>

<style lang="scss" scoped>
.category {
  width: 1330px;
  @media (max-width: 1000px) {
    width: 100% !important;
  }
  margin-left: 9px;
  margin-right: 9px;
  max-width: 1440px;
  margin: 80px auto;
  cursor: pointer;

  .title {
    margin-bottom: 10px;
    font-size: 30px;
  }

  .category-list {
    display: flex;
    justify-content: space-between;
    position: relative;
    @media (max-width: 1000px) {
      width: calc(100% - 5px) !important;
    }

    .item {
      margin-right: 5px;
      .img-row{
        width: calc((100vw - 40px)/5);
        height: 170px;

        @media (max-width: 1000px) {
          width: auto !important;
          height: 150px !important;
          margin: 5px;
        }
      }
      img {
        width: 247px;
        height: 170px;

        @media (max-width: 1000px) {
          width: auto !important;
          height: 150px !important;
          margin: 5px;
        }
      }

      .white-h {
        position: absolute;
        top: 0;
        display: none;
        width: 247px;
        height: 170px;
        background: rgba(255, 255, 255, 0.65);
      }
    }

    .item:hover .white-h {
      display: block;
    }
  }

  .cate {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    .left {
      position: relative;

      .left-img {
        width: calc(60vw - 8px);
        height: 364px;
      }

      img {
        width: 788px;
        height: 364px;
      }

      .white-h {
        position: absolute;
        top: 0;
        display: none;
        width: 788px;
        height: 364px;
        background: rgba(255, 255, 255, 0.65);
      }
    }

    .left:hover .white-h {
      display: block;
    }

    .right {
      height: 364px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .item {
        position: relative;
        .img-right {
          width: 40vw
        }
        img {
          width: 518px;
          height: 170px;
        }

        .white-h {
          position: absolute;
          top: 0;
          display: none;
          width: 518px;
          height: 170px;
          background: rgba(255, 255, 255, 0.65);
        }
      }

      .item:hover .white-h {
        display: block;
      }
    }
  }
  .isMobile {
    width: 100%;
    padding: 0 5px;
    .box1 {
      display: flex;
      height: 170px;
      width: 100%;
      .item,
      .left {
        margin-top: 5px;
        display: flex;
        flex: 1;
      }
    }
  }
}
</style>
