<template>
	<div class="main">
		<!-- 头部 -->
		<Header @catListLoaded="catListLoaded" v-show="page"></Header>
		<!-- unuse animation, please check setTimeOut-->
		<!-- unuse animation, please check setTimeOut-->
		<div v-show="page">
			<!-- 轮播图 -->
			<template v-if="!token">
				<Swiper />
			</template>

			<template v-if="token">
				<Shops :catList="catList"></Shops>
			</template>

			<!-- 搜索 -->

			<!-- <template v-if="token">
				<div class="search">
					<div class="f-s-30">Recent Search</div>
					<div style="
              width: 100%;
              height: 2px;
              background-color: #406055;
              margin: 10px 0 19px;
            "></div>
					<div style="display: flex">
						<div v-for="i in 5" :key="i">
							<div class="mag-r-10 bod-ra-50px f-s-20 relative PP-R"
								style="padding: 4px 18px 5px 35px; border: 1px solid #406055">
								Pencil
								<div class="absolute" style="left: 10px; top: 10px">
									<img style="width: 20px; height: 20px" src="../../assets/image/Search.png" alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</template> -->
			<!-- <div style="height: 86px;"></div> -->
			<!-- 推荐 -->
			<template v-if="!token">
				<div class="rememnd flex-row">
					<div class="item-card tx-cen">
						<img src="../../assets/image/CardPayment.png" alt="" />
						<div class="title VC-R">Flexible Payment</div>
						<div class="f-s-14 content">Buy Now, Pay up to 60 days later</div>
						<div class="f-s-14 content">Interest Free</div>
					</div>
					<div class="item-card tx-cen">
						<img src="../../assets/image/GetRevenue.png" alt="" />
						<div class="title VC-R">Boost your revenue</div>
						<div class="f-s-14 content desktop-content">
							Best selling products to Boost Your revenue
						</div>
						<div class="f-s-14 content">
							All products and brands sold have a proven track record to help
							your store thrive
						</div>
					</div>
					<div class="item-card tx-cen">
						<img src="../../assets/image/Transaction.png" alt="" />
						<div class="title VC-R">Free Return</div>
						<div class="f-s-14 content">Free returns on opening order</div>
						<div class="f-s-14 content">
							Risk Free trails on all new Products and brands,
						</div>
						<div class="f-s-14 content desktop-content">Discover your next best seller</div>
					</div>
				</div>
			</template>

			<!-- 商品推荐列表 -->
			<shop-list compKey="Featured" 
				title="Featured" 
				requestPath="api/default/goods-feature" 
				style="margin-top: 32px;" 
				mchId=""></shop-list>

			<template v-if="token">
				<shop-list
					compKey="NewArrival"
					title="New Arrival"
					mchId=""
					requestPath="api/default/goods-feature" 
					style="margin-top: 129px"
				></shop-list>
			</template>
			<!-- 类别 -->
			<div>
				<CatStatic />
			</div>
			<!-- 中间 -->
			<template v-if="!token">
				<Middles />
			</template>

			<template v-if="token">
				<shop-list
					compKey="TopRanking"
					title="Top Ranking"
					mchId=""
					requestPath="api/default/goods-top-rank" 
					style="margin-top: 129px"
				></shop-list>
			</template>

			<template v-if="!token">
				<shop-list compKey="NewArrival" title="New Arrival"></shop-list>
			</template>

			<!-- <template v-if="!token">
				<Trending></Trending>
			</template> -->

			<template v-if="!token">
				<div class="mission !hidden lg:!flex">
					<div class="left">
						<div class="f-s-52 VC-R" style="padding: 0 10px">Mission</div>
						<p
							class="f-s-22 PP-R"
							style="width: 75%; padding: 25px 0 0 36px; line-height: 30px"
						>
							Our mission is to empower small business owners by providing
							access to a diverse range of high-quality products sourced from
							around the globe, all offered at competitive prices.
						</p>
						<p
							class="f-s-22 PP-R"
							style="
								width: 75%;
								height: 263px;
								padding: 0 0 45px 36px;
								line-height: 30px;
							"
						>
							Through our commitment to delivering excellence, we aim to be the
							trusted partner that small businesses rely on for sourcing
							innovative and reliable products, ultimately contributing to their
							success and prosperity.
						</p>
					</div>
					<div
						class="right"
						style="position: absolute; right: 0px; top: 92px; width: 55%"
					>
						<img src="../../assets/image/glob2.jpg" alt="" />
					</div>
				</div>
				<div
					class="mission-bg flex flex-col px-4 py-6 lg:hidden my-[150px] text-white"
					style="
						background: linear-gradient(
							113.75deg,
							rgba(64, 96, 85, 1),
							rgba(222, 229, 211, 1)
						) !important;
					"
				>
					<div class="f-s-48 VC-R">Mission</div>
					<p class="f-s-20 PP-R">
						Our mission is to empower small business owners by providing access
						to a diverse range of high-quality products sourced from around the
						globe, all offered at competitive prices.
					</p>
					<p class="f-s-20 PP-R">
						Through our commitment to delivering excellence, we aim to be the
						trusted partner that small businesses rely on for sourcing
						innovative and reliable products, ultimately contributing to their
						success and prosperity.
					</p>
					<div class="t-d-u f-s-22 c-p PP-R">Click to learn more</div>
				</div>
			</template>

			<template v-if="token">
				<!-- <Missions /> -->
				<div class="missions">
					<div class="left">
						<div
							class="f-s-30 VC-R"
							:style="
								isMobile()
									? 'text-align:center'
									: 'margin-left: 67px; line-height: 43px'
							"
						>
							You've got 50% off your first order!
						</div>
						<div class="f-s-22 PP-R tx-cen"></div>
						<div
							class="f-s-20"
							:style="
								isMobile()
									? 'text-align:center'
									: 'margin: 22px 0 22px 67px; width: 380px'
							"
						>
							Welcome to WAVF, get 50% off your first order up to $150 
						</div>
						<div
							class=""
							:style="
								isMobile()
									? 'text-align:center'
									: 'width: 480px; margin-left: 67px'
							"
						>
							offer expires in 2 weeks.
						</div>
					</div>
					<div
						class="right"
						style="position: relative; left: -150px; top: 43px"
					>
						<el-image
							style="width: 140%; height: 374px"
							:src="src"
							fit="cover"
						></el-image>
					</div>
				</div>
			</template>

			<!-- <template v-if="token">
				<shop-list
					title="New Arrival"
					compKey="NewArrival"
					style="margin-top: 129px"
				></shop-list>
			</template>

			<template v-if="token">
				<shop-list
					title="Top Ranking"
					compKey="TopRanking"
					style="margin-top: 129px"
				></shop-list>
			</template>

			<template v-if="token">
				<shop-list
					compKey="NewArrival"
					title="New Arrival"
					style="margin-top: 129px; margin-bottom: 129px"
				></shop-list>
			</template>

			<shop-list
				compKey="Explore"
				title="Explore"
				:subTitle="subtitle"
			></shop-list>

			<div>
				<SellOnWavf />
			</div> -->

			<template v-if="!token">
				<div class="fooder !hidden lg:!flex">
					<div class="left">
						<div class="VC-R" style="font-size: 52px; margin: 0 128px 58px 0">
							Register Today
						</div>
						<div
							class="PP-R c-p flex"
							@click="goWavfLogin"
							style="
								background-color: #000;
								border-radius: 50px;
								padding: 12px 64px 12px 65px;
								margin-right: 128px;
							"
						>
							<div>Sign Up to Shop</div>
						</div>
					</div>
					<div class="right">
						<img src="../../assets/image/index_footer.png" alt="" />
					</div>
				</div>
				<div
					class="lg:hidden relative w-full flex flex-col justify-center px-4 min-h-60 mt-32"
				>
					<div class="text-5xl text-white mb-5">Register Today</div>
					<div
						class="PP-R c-p flex text-white w-full justify-center"
						@click="goWavfLogin"
						style="
							background-color: #000;
							border-radius: 50px;
							padding: 12px 64px 12px 65px;
							margin-right: 128px;
						"
					>
						Sign Up to Shop
					</div>
					<!-- <div class="">
						<div class="VC-R" style="font-size: 52px; margin: 0 128px 58px 0">
							Register Today
						</div>
						<div class="PP-R c-p flex" @click="goWavfLogin" style="
                background-color: #000;
                border-radius: 50px;
                padding: 12px 64px 12px 65px;
                margin-right: 128px;
              ">
							<div>Sign Up to Shop</div>
						</div>
					</div> -->
					<div class="absolute inset-0 -z-10">
						<img
							src="../../assets/image/index_footer.png"
							alt=""
							class="w-full h-full object-cover"
						/>
					</div>
				</div>
			</template>

			<Fooder></Fooder>

			<!-- <div
				style="
					position: fixed;
					background-color: #406055;
					width: 40px;
					display: flex;
					height: 40px;
					border-radius: 50%;
					left: 20px;
					justify-content: center;
					align-items: center;
					z-index: 10000;
					top: 550px;
				"
			>
				<img
					style="width: 25px; height: 25px"
					src="../../assets/image/ChatMessage.png"
					alt=""
				/>
			</div> -->

			<el-backtop
				visibility-height="200"
				target=".page-component__scroll .el-scrollbar__wrap"
			></el-backtop>
   
			<div
        @click="goTop"
        style="
          position: fixed;
          background-color: #406055;
          width: 35px;
          display: flex;
          height: 35px;
          right: 20px;
          justify-content: center;
          align-items: center;
          z-index: 10000;
          top: 620px;
        "
      >
        <img
          style="width: 30px; height: 18px"
          src="../../assets/image/Vector.png"
          alt=""
        />
      </div>
		</div>
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
import Fooder from "@/components/Fooder.vue";
import ShopList from "@/components/ShopList.vue";
import Swiper from "./components/Swiper.vue";
import Shops from "./components/Shops.vue";
import Middles from "@/components/Middles.vue";
import Trending from "@/components/Trending.vue";
// import Missions from "@/components/Missions.vue";
import SellOnWavf from "@/components/SellOnWavf.vue";
import CatStatic from "@/components/CatStatic.vue";
import { mapState } from "vuex";

export default {
	name: "Home",
	components: {
		Header,
		ShopList,
		Fooder,
		Swiper,
		Shops,
		Middles,
		Trending,
		// Missions,
		SellOnWavf,
		CatStatic,
	},
	data() {
		return {
			fontSizeFYN: "76px",
			fontSizeBS: "96px",
			imgWidth: "17vw",
			subtitle: "Shop over 100,000 independent wholesale suppliers",
			subtitle: "Shop unique brands from around the world",

			morens: "zidinyi",
			src: require("../../assets/image/glob.jpg"),
			shows: false,
			page: false,
			classObject: {
				active: false,
				activej: false,
			},
			classActive: {
				activetop: false,
				activetopj: false,
			},
			scail: false,
			time: null,
			times: null,
			catList: [],
		};
	},
	computed: {
		...mapState(["token"]),
	},
	mounted() {
		sessionStorage.setItem("showLogin", true);

		if (this.isMobile()) {
			// console.log("手机端");
			this.imgWidth = "57vw";
			this.fontSizeFYN = "36px";
			this.fontSizeBS = "46px";
		} else {
			// console.log("PC端");
			this.imgWidth = "17vw";
			this.fontSizeFYN = "76px";
			this.fontSizeBS = "96px";
		}
		this.shows = true;
		this.classObject.active = true;
		this.classActive.activetop = true;
		this.page = true;
		this.classObject.activej = true;
		this.classActive.activetopj = true;
		this.scail = true;
	},
	methods: {
		isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			);
			return flag;
		},
		catListLoaded(data) {
			this.catList = data;
		},
		goWavfLogin() {
			if (this.isMobile()) {
				this.$router.push("/wavfloginMobile");
			} else {
				this.$router.push("/wavflogin");
			}
		},

		goTop() {
			document.body.scrollTop = 0;
		},
	},
};
</script>

<style lang="scss" scoped>
@import url("@/assets/styles/home.scss");
.main {
	overflow: hidden;
	position: relative;
	.desktop-content{
			display: block;
		}
	.rememnd {
		// width: 1400px;
		//@apply container;
		max-width: 1330px;
		margin: 34px auto;
		display: flex;
		justify-content: space-between;
		padding: 65px 0;
		flex-direction: row;
		.item-card {
			width: 350px;

			.title {
				font-size: 30px;
				margin: 7px 0;
			}

			img {
				width: 50px;
				height: 50px;
				margin: 0 auto;
			}
			@media (max-width: 800px) {
				.title {
					font-size: 24px;
					margin: 7px 0;
				}
			}
		}
	}
	@media (max-width: 800px) {
		.desktop-content{
			display: none;
		}
			.rememnd {
				margin: 14px auto;
				padding: 5px 8px !important;
				.item-card {
					width: 32%;
					.title {
						font-size: 17px;
						margin: 7px 0;
					}
					.content {
						font-size: 12px;
					}
				}
			}
		}

	.search {
		width: 1330px;
		margin: 43px auto 93px;
	}

	.category {
		width: 80vw;
		margin: 80px auto;
		cursor: pointer;

		.title {
			margin-bottom: 10px;
			font-size: 30px;
		}

		.category-list {
			display: flex;
			justify-content: space-between;
			position: relative;

			.item {
				img {
					width: 247px;
					height: 170px;
				}

				.white-h {
					position: absolute;
					top: 0;
					display: none;
					width: 247px;
					height: 170px;
					background: rgba(255, 255, 255, 0.65);
				}
			}

			.item:hover .white-h {
				display: block;
			}
		}

		.cate {
			margin-top: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.left {
				position: relative;

				img {
					width: 788px;
					height: 364px;
				}

				.white-h {
					position: absolute;
					top: 0;
					display: none;
					width: 788px;
					height: 364px;
					background: rgba(255, 255, 255, 0.65);
				}
			}

			.left:hover .white-h {
				display: block;
			}

			.right {
				height: 364px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				.item {
					position: relative;

					img {
						width: 518px;
						height: 170px;
					}

					.white-h {
						position: absolute;
						top: 0;
						display: none;
						width: 518px;
						height: 170px;
						background: rgba(255, 255, 255, 0.65);
					}
				}

				.item:hover .white-h {
					display: block;
				}
			}
		}
	}

	.middles {
		color: #fff;
		width: 100%;
		min-width: 1330px;
		height: 300px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 30px;
		background: linear-gradient(
			360deg,
			rgba(64, 96, 85, 1),
			rgba(222, 229, 211, 1)
		);

		img {
			width: 100px;
			height: 100px;
		}
	}

	.trending {
		margin: 82px auto 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.mission-bg {
		background: linear-gradient(
			113.75deg,
			rgba(64, 96, 85, 1),
			rgba(222, 229, 211, 1)
		) !important;
	}
	.mission {
		width: 80vw;		
		@media (max-width: 1000px) {
			width: calc(100% - 5px) !important;
		}
		max-width: 1440px;
		margin: 147px auto;
		position: relative;
		display: flex;
		justify-content: space-between;

		.left {
			color: #fff;
			width: 60%;
			height: 570px;
			background: linear-gradient(
				113.75deg,
				rgba(64, 96, 85, 1),
				rgba(222, 229, 211, 1)
			);

			@media (max-width: 1000px) {
				width: calc(100% - 5px) !important;
			}
		}

		.right {
			img {
				width: 153%;
				height: 495px;
			}
		}
	}

	.missions {
		width: 80vw;
		margin: 147px auto;
		position: relative;
		display: flex;
		justify-content: space-between;

		.left {
			color: #fff;
			width: 1338px;
			height: 374px;
			display: flex;
			flex-direction: column;
			// align-items: center;
			justify-content: center;
			background: linear-gradient(
				113.75deg,
				rgba(64, 96, 85, 1),
				rgba(222, 229, 211, 1)
			);
			@media (max-width: 1000px) {
				width: calc(100%) !important;
			}
		}

		.right {
			@media (max-width: 1000px) {
				display: none !important;
			}
		}
	}

	.sell-wavf {
		color: #fff;
		height: 300px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 1330px;
		@media (max-width: 1000px) {
			width: calc(100% - 5px) !important;
		}

		height: 462px;
		background-color: rgba(0, 0, 0, 0.56);
		display: flex;
		font-size: 22px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: linear-gradient(
			180deg,
			rgba(64, 96, 85, 0.9) 0%,
			rgba(166, 195, 174, 0.9) 100%
		);
	}

	.fooder {
		margin-top: 107px;
		display: flex;
		align-items: center;
		width: 120vw;
		.left {
			color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-left: -150px;
			height: 452px;
			width: 1938px;
			background: linear-gradient(
				179.83deg,
				rgba(64, 96, 85, 0.9),
				rgba(166, 195, 174, 0.9)
			);
		}

		.right {
			position: relative;
			left: -20vw;

			img {
				width: 180%;
				height: 374px;
			}
		}
	}

	.text-span {
		animation: move 4s linear;
	}

	.donghua {
		background: url("../../assets/image/donghuabg.png") no-repeat;
		background-size: 200% 350%;
		background-position: 50% 0px;
	}

	.active {
		animation: shou 3s;
		animation-fill-mode: forwards;
	}

	.activej {
		animation: shou-ji 2s;
		animation-fill-mode: forwards;
	}

	@keyframes move {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@keyframes shou {
		0% {
			background-position: 50% 0px;
		}

		100% {
			background-position: 50% -400px;
		}
	}

	// @keyframes shou-ji {
	//   0% {
	//     background-position: 50% -400px;
	//   }

	//   100% {
	//     background-position: 50% -1000px;
	//   }
	// }

	.activetop {
		animation: moves 2s;
		animation-fill-mode: forwards;
	}

	.active-move {
		animation: topmoves 2s;
		animation-fill-mode: forwards;
	}

	.activetopj {
		animation: movesj 1.8s;
		animation-fill-mode: forwards;
	}

	@keyframes moves {
		0% {
			margin-top: 0;
		}

		100% {
			margin-top: -150px;
		}
	}

	@keyframes movesj {
		0% {
			margin-top: -150px;
		}

		100% {
			margin-top: -600px;
		}
	}

	@keyframes topmoves {
		0% {
			margin-top: 78px;
		}

		100% {
			margin-top: 0px;
		}
	}

	.img-scal {
		animation: scal 1.8s;
		animation-fill-mode: forwards;
	}

	.img-scals {
		animation: scals 1.8s;
		animation-fill-mode: forwards;
	}

	@keyframes scal {
		0% {
			transform: translate(0, 0) scale(1);
		}

		100% {
			transform: translate(2000px, -800px) scale(3.4);
		}
	}

	@keyframes scals {
		0% {
			transform: translate(0, 0) scale(1);
		}

		100% {
			transform: translate(2000px, -800px) scale(3.4);
		}
	}
}
</style>
