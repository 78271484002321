<template>
	<div>
		<div class="main-h hidden lg:block z-index-100" style="">
			<div class="header flex al-item juc-center">
				<img src="../assets/image/Fire.png" />
				<div class="mag-l-10 f-s-14">
					WAVF, Your Gateway to Quality and Affordability, Simplified.
				</div>
			</div>
			<div class="middle flex al-item juc-sb">
				<div class="left">
					<div class="logo c-p">
						<a href="/home">
							<img src="../assets/image/LOGO.svg" alt="" />
						</a>
					</div>
					<div class="int relative hidden lg:block">
						<el-input
							@keyup.enter.native="goSearch"
							v-model="search"
						></el-input>
						<img
							@click="goSearch"
							class="search-icon c-p"
							src="../assets/image/Search.png"
							alt=""
						/>
					</div>
				</div>
				<div
					class="right flex al-item"
					style="font-family: 'Pp-Black'; z-index: 1000000000"
				>
					<el-dropdown
						@command="handleCommand"
						class="c-p drop-d"
						style="z-index: 1000000000"
						trigger="click"
					>
						<img src="../assets/image/Geography.png" alt="" />
						<el-dropdown-menu slot="dropdown" style="z-index: 100000000">
							<el-dropdown-item command="english">English</el-dropdown-item>
							<el-dropdown-item command="french">French</el-dropdown-item>
							<el-dropdown-item command="spanish">Spanish</el-dropdown-item>
							<el-dropdown-item command="chinese_simplified"
								>Simplified Chinese</el-dropdown-item
							>
						</el-dropdown-menu>
					</el-dropdown>
					<!-- <div class="shu-line"></div> -->
					<!-- <div @click="goBlog" class="c-p">Blog</div> -->
					<div class="shu-line"></div>
					<div class="c-p sell-wavf" @click="goBuinessR">Sell on WAVF</div>
					<div class="shu-line"></div>
					<div v-if="!token" @click="goLogin" class="c-p sgin-in">Sign In</div>
					<div v-if="!token" class="shu-line"></div>
					<div v-if="!token" class="sign-up c-p" @click="goWavfLogin">
						Sign Up to Shop
					</div>
					<div v-if="token" class="flex al-item c-p logo-icon">
						<img
							@mouseenter="openHoverPerCenter"
							style="width: 31px; height: 31px"
							src="../assets/image/Customer.png"
							alt=""
						/>
						<el-badge
							:value="wishLength"
							class="item"
							:hidden="wishLength == 0"
						>
							<img
								@click.stop="toWishlist"
								style="width: 29px; height: 29px; margin: 0 30px"
								src="../assets/image/Heart.png"
								alt=""
							/>
						</el-badge>
						<el-badge
							:value="cartLength"
							class="item"
							:hidden="cartLength == 0"
						>
							<img
								style="width: 30px; height: 30px"
								@click="toCart"
								src="../assets/image/Shopping Bag.png"
								alt=""
							/>
						</el-badge>
					</div>
				</div>
			</div>

			<!-- <el-menu :default-active="activeIndex" class="el-menu-demo fooder flex al-item jus-se" mode="horizontal" @select="handleSelect">
			<div v-for="(item,index) in catList">
				<el-menu-item :index="''+index" v-if="!item.list">{{item.name}}</el-menu-item>
				<el-submenu :index="''+index" v-else>
					<template slot="title">{{item.name}}</template>
					<div v-for="(item2,index2) in item.list">
						<el-menu-item :index="index + '-'+ index2" v-if="!item2.list">{{item2.name}}</el-menu-item>
						<el-submenu :index="index + '-'+ index2" v-else>
							<template slot="title">{{item2.name}}</template>
							<div v-for="(item3,index3) in item2.list">
								<el-menu-item :index="index + '-'+ index2 + '-'+index3" v-if="!item3.list">{{item3.name}}</el-menu-item>
								<el-submenu :index="index + '-'+ index2 + '-'+index3"  v-else>
									<template slot="title">{{item3.name}}</template>
								</el-submenu>
							</div>
						</el-submenu>
					</div>

				</el-submenu>

			</div>
		</el-menu> -->

			<div class="hover-menu-root" @mouseleave="menuMouseLeave">
				<div class="fooder flex al-item jus-se">
					<div
						class="item-list"
						@mouseenter="menuMouseEnter(item)"
						@click.stop="goClass(item)"
						v-for="(item, index) in catList"
						:key="index + 10"
					>
						{{ item.name }}
					</div>
				</div>

				<div class="hover-menu-box" v-show="hoveMenuShow">
					<div class="flex-row-el" style="margin-left: 20vw">
						<div class="left-box">
							<div
								@click.stop="goClass(slItem)"
								v-for="(slItem, slIndex) in currentParentMenu.list"
								:key="slIndex + 100"
							>
								<div class="menu-item" @mouseenter="slMenuMouseEnter(slItem)">
									{{ slItem.name }}
								</div>
							</div>
						</div>
						<div class="right-box">
							<div class="level3-box">
								<div
									class="sl-menu-item"
									@click.stop="goClass(ltItem)"
									v-for="(ltItem, ltIndex) in currentSecondLevel1Menu.list"
									:key="ltIndex + 10000"
								>
									{{ ltItem.name }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				class="personal-center"
				@mouseleave="closeHoverPerCenter"
				v-show="hovePerCenterShow"
			>
				<div class="pla-box">
					<div class="title">Hi,{{ nickname }}</div>
					<div class="email">{{ myData.username }}</div>
				</div>
				<div class="action-list">
					<div class="action-item">
						<img
							src="https://img.js.design/assets/img/65d02fe6cd29196e3531a504.png#a27f3545a0c8a5b7382a109c71c3ef34"
						/>
						<div @click="goOrder" class="action-name">Order history</div>
					</div>
					<div class="action-item">
						<img
							src="https://img.js.design/assets/img/66120a085900e1ab016ed631.png#2cfeb96488f2ae081bb1d470c0528c2c"
						/>
						<div @click="goUser" class="action-name">Manage account</div>
					</div>
					<div class="action-item-gap"></div>
					<div class="action-item" @click="toHelpCenter">
						<img
							src="https://img.js.design/assets/img/65f2a49473ed277c3a1fa8e0.png#15a33faefea35c12e05c62c1ba559fb6"
						/>
						<div class="action-name">Help centre</div>
					</div>
					<div class="action-item" @click="contactUs">
						<img
							src="https://img.js.design/assets/img/66120a08a9625e86c367d967.png#c584dca7d92f89b70b0f3207bae9d07a"
						/>
						<div class="action-name">Contact us</div>
					</div>
					<div class="action-item-gap"></div>
					<div class="action-item" @click.stop="logout">
						<img
							src="https://img.js.design/assets/img/66120a085fb96ffae58b4382.png#5ba254a4ae6ca36ba3b84a2ec7b200df"
						/>
						<div class="action-name">Log out</div>
					</div>
				</div>
				<div
					class="logo-box flex-row-el flex-align-center"
					style="justify-content: center"
				>
					<img src="../../src/assets/Logo.png" />
				</div>
			</div>
			<div class="bottom-bg"></div>
		</div>
		<div class="lg:hidden p-4 flex justify-between items-center">
			<div class="flex items-center" style="flex-grow: 1">
				<svg
					@click="onMenuOpen"
					xmlns="http://www.w3.org/2000/svg"
					width="28"
					height="28"
					viewBox="0 0 24 24"
					fill="none"
					stroke="currentColor"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
					class="lucide lucide-align-justify"
				>
					<line x1="3" x2="21" y1="6" y2="6" />
					<line x1="3" x2="21" y1="12" y2="12" />
					<line x1="3" x2="21" y1="18" y2="18" />
				</svg>
				<a href="/">
					<img src="../../src/assets/Logo.png" class="h-7" />
				</a>
			</div>
			<svg
				:style="`${
					isMobile() && !token
						? 'flex-grow: 0; flex-shrink: 0;flex-basis: auto;margin-right:10px'
						: ''
				}`"
				v-show="$route.path !== '/search'"
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
				class="lucide lucide-search"
				@click="$router.push('/search')"
			>
				<circle cx="11" cy="11" r="8" />
				<path d="m21 21-4.3-4.3" />
			</svg>
			<div v-if="token">
				<el-badge :value="cartLength" class="item" :hidden="cartLength == 0" style="margin-left: 16px;">
				<img style="width: 30px; height: 30px;" @click="toCart" src="../assets/image/Shopping Bag.png" alt="" />
			</el-badge>
			</div>
			<div
				v-if="!token"
				style="
					flex-grow: 0;
					flex-shrink: 0;
					flex-basis: auto;
					background: #000;
					color: #fff;
					padding: 5px 10px;
					border-radius: 8px;
				"
				@click="$router.push('/wavfloginMobile')"
			>
				Sign Up to Shop
			</div>
		</div>
		<div v-if="menuVisible" class="fixed w-screen inset-0 z-[999999]">
			<div class="bg-white w-4/5 h-full">
				<div class="p-4 flex justify-between items-center border-b shadow">
					<a href="/home">
						<img src="../assets/image/LOGO.svg" alt="" class="h-6" />
					</a>
					<svg
						@click="onMenuClose"
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
						class="lucide lucide-x"
					>
						<path d="M18 6 6 18" />
						<path d="m6 6 12 12" />
					</svg>
				</div>

				<div
					v-if="lastMenu.length > 0"
					@click="onMenuBack()"
					class="flex gap-x-2 items-center p-2 border-b"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
						class="lucide lucide-chevron-left"
					>
						<path d="m15 18-6-6 6-6" />
					</svg>
					<span> back</span>
				</div>

				<div class="space-y-4 px-4 py-2">
					<ul class="flex flex-col divide-y" v-if="lastMenu.length == 0">
						<li
							class="py-2 flex justify-between items-center"
							@click="onMenuClick({ list: ENUSList })"
						>
							EN-US
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="lucide lucide-chevron-right"
							>
								<path d="m9 18 6-6-6-6" />
							</svg>
						</li>
						<li class="py-2 flex justify-between items-center" @click="goBlog">
							Blog
						</li>
						<li
							class="py-2 flex justify-between items-center"
							@click="goBuinessR"
						>
							Sell on WAVF
						</li>
						<li
							v-if="!token"
							class="py-2 flex justify-between items-center"
							@click="
								showLoginModal = true;
								onMenuClose();
							"
						>
							Sign In
						</li>
						<li
							v-if="!token"
							class="py-2 flex justify-between items-center"
							@click="$router.push('/wavfloginMobile')"
						>
							Sign Up to Shop
						</li>
					</ul>

					<ul
						v-if="currentMenu && currentMenu.length > 0"
						class="flex flex-col divide-y"
					>
						<li
							class="py-2 flex justify-between items-center"
							v-for="cat in currentMenu"
							:key="cat.id"
						>
							<div
								@click="
									onMenuClose();
									goClass(cat);
								"
							>
								{{ cat.name }}
							</div>
							<svg
								v-if="cat.list && cat.list.length > 0"
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="lucide lucide-chevron-right"
								@click="onMenuClick(cat)"
							>
								<path d="m9 18 6-6-6-6" />
							</svg>
						</li>
					</ul>

					<div class="flex flex-col divide-y" v-if="token">
						<div
							class="flex gap-x-2 py-2 items-center"
							@click.stop="toWishlist"
						>
							<img class="h-6 w-6" src="../assets/image/Heart.png" />
							<div class="">Your Wishlist</div>
						</div>
						<div class="flex gap-x-2 py-2 items-center cursor-pointer" @click="toCart">
							<img class="h-6 w-6" src="../assets/image/Shopping Bag.png" />
							<div class="">Shopping Cart</div>
						</div>
						<div class="flex gap-x-2 py-2 items-center cursor-pointer" @click="goOrder">
							<img
								class="h-6 w-6"
								src="https://img.js.design/assets/img/65d02fe6cd29196e3531a504.png#a27f3545a0c8a5b7382a109c71c3ef34"
							/>
							<div class="">Order history</div>
						</div>
						<div class="flex gap-x-2 py-2 items-center cursor-pointer" @click="goUser">
							<img
								class="h-6 w-6"
								src="https://img.js.design/assets/img/66120a085900e1ab016ed631.png#2cfeb96488f2ae081bb1d470c0528c2c"
							/>
							<div class="">Manage account</div>
						</div>
						<div class="flex gap-x-2 py-2 items-center cursor-pointer" @click="toHelpCenter">
							<img
								class="h-6 w-6"
								src="https://img.js.design/assets/img/65f2a49473ed277c3a1fa8e0.png#15a33faefea35c12e05c62c1ba559fb6"
							/>
							<div class="">Help centre</div>
						</div>
						<div class="flex gap-x-2 py-2 items-center" @click="contactUs">
							<img
								class="h-6 w-6"
								src="https://img.js.design/assets/img/66120a08a9625e86c367d967.png#c584dca7d92f89b70b0f3207bae9d07a"
							/>
							<div class="">Contact us</div>
						</div>
						<div class="flex gap-x-2 py-2 items-center" @click.stop="logout">
							<img
								class="h-6 w-6"
								src="https://img.js.design/assets/img/66120a085fb96ffae58b4382.png#5ba254a4ae6ca36ba3b84a2ec7b200df"
							/>
							<div class="">Log out</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bg-black/50 absolute inset-0 -z-10" @click="onMenuClose" />
		</div>
		<el-dialog
			v-if="!isMobile()"
			title="Sign in"
			width="600px"
			top="20vh"
			:visible.sync="showLoginModal"
		>
			<Login></Login>
		</el-dialog>
		<div v-else v-show="showLoginModal" class="loginDialog">
			<div class="loginDialogBox">
				<div class="title">Sign in</div>
				<i class="el-icon-close icon" @click="showLoginModal = false"></i>
				<Login></Login>
			</div>
		</div>
	</div>
</template>

<script>
import wavfloginMobile from "@/views/login/wavfloginMobile.vue";
import Login from "@/components/Login.vue";
import { commonApi } from "@/api/common";
import { mapState } from "vuex";
// import { translate } from "@/utils/translate";
export default {
	components: {
		Login,
		wavfloginMobile,
	},

	inject: ["reload"],
	data() {
		return {
			ENUSList: [
				{ name: "English", id: 1, list: [] },
				{ name: "French", id: 1, list: [] },
				{ name: "Spanish", id: 1, list: [] },
				{ name: "Chinese", id: 1, list: [] },
			],
			signUpToShopShow: false,
			dialogLoginShow: true,
			showLoginModal: false,
			hovePerCenterShow: false,
			currentSecondLevel1Menu: {
				list: [],
			}, //当前选中的菜单二级节点
			currentParentMenu: {}, //当前选中的菜单一级节点
			hoveMenuShow: false,
			activeIndex: "1",
			activeIndex2: "1",
			search: "",
			navlist: [],
			catList: [],
			myData: {},
			menuVisible: false,
			searchVisible: false,
			currentMenu: undefined,
			lastMenu: [],
			cartLength: window.localStorage["cartLength"]
				? window.localStorage["cartLength"]
				: 0,
			wishLength: window.localStorage["wishLength"]
				? window.localStorage["wishLength"]
				: 0,
		};
	},
	computed: {
		...mapState(["token"]),
		...mapState(["nickname"]),
		...mapState(["userinfo"]),
	},

	watch: {
		showLoginModal(val) {
			if (val && this.isMobile()) {
				document.body.style.overflow = "hidden";
			} else {
				document.body.style.overflow = "auto";
			}
		},
	},
	created() {
		this.loadMyInfo();

		if (this.token) {
			this.loadCartData();
			this.loadWishData();
		}
	},
	mounted() {
		this.getCatList();
		this.getExchangeRate();
		document.body.style.overflow = "auto";
		setTimeout(() => {
			this.showLoginModal = !this.token && !this.isMobile() && this.$route.name != 'resetPassword' && this.$route.name != 'forgetPassword';
		}, 1000);
	},
	methods: {
		showLoginFun() {
			this.showLoginModal = true;
		},
		goENUS() {},
		setCartLen(len) {
			this.cartLength = len;
			window.localStorage["cartLength"] = len;
		},
		setWishLen(len) {
			this.wishLength = len;
			window.localStorage["wishLength"] = len;
		},
		loadCartData() {
			this.$post({
				r: "api/stripe/cart-preview",
			}).then((r) => {
				if (r && r.data && r.data.code === 0) {
					this.setCartLen(r.data.data.list.length);
				}
			});
		},
		loadWishData() {
			this.$get({
				r: "api/user/favorite-list",
			}).then((r) => {
				if (r && r.data && r.data.code === 0) {
					this.setWishLen(r.data.data.row_count);
				}
			});
		},
		toWishlist() {
			this.$router.push("/wishlist");
		},
		isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			);
			return flag;
		},
		loadMyInfo() {
			this.$get({
				r: "api/user/user-center",
			}).then((res) => {
				if (res.data.code === 0) {
					this.myData = res.data.data;
				}
			});
		},
		logout() {
			this.$confirm("Are you sure you want to exit", "Prompt", {
				confirmButtonText: "Confirm",
				cancelButtonText: "Cancel",
				type: "warning",
			})
				.then(() => {
					localStorage.removeItem("token");
					localStorage.removeItem("userCacheData");
					localStorage.removeItem("nickname");
					localStorage.removeItem("userinfo");
					this.$store.commit("setToekn", null);
					this.$store.commit("setNickName", null);
					this.menuVisible = false;
                    this.$router.push("/home");
                })
				.catch(() => {});
		},
		openHoverPerCenter() {
			this.hovePerCenterShow = true;
		},
		closeHoverPerCenter() {
			this.hovePerCenterShow = false;
		},
		/**
		 *
		 */
		slMenuMouseEnter(currentSecondLevel1Menu) {
			this.currentSecondLevel1Menu = currentSecondLevel1Menu;
		},
		menuMouseEnter(item) {
			this.hoveMenuShow = true;
			if (item) {
				this.currentParentMenu = item;
				if (item.list && item.list.length > 0) {
					this.currentSecondLevel1Menu = item.list[0];
				}
			}
		},
		menuMouseLeave() {
			this.hoveMenuShow = false;
		},
		handleSelect(key, keyPath) {
			// console.log(key, keyPath);
		},
		async getCatList() {
			const res = await commonApi({
				r: "api/default/cat-list",
			});
			this.catList = res.data.data.list;
			this.currentMenu = this.catList;
			this.$emit("catListLoaded", res.data.data.list);
			// console.log("cat-list", this.catList);
		},
		async getNavList() {
			// console.log("111");
			const res = await commonApi({
				r: "api/default/nav",
			});
			this.navlist = res.data.data.data;
		},
		goOrder() {
			this.$router.push("/user/order");
		},
		goUser() {
			this.$router.push("/user/my_profile");
		},
        goLogin() {
            this.$router.push("/login");
        },
		contactUs() {
			this.$router.push("/contact_us");
		},
		goBlog() {
			this.$router.push("/bloghome");
		},
		goClass(item) {
			const temp = ["English", "French", "Spanish", "Chinese"];
			if (temp.includes(item.name)) return;
			// console.log("item: ", item);
			// console.log("我了个去");
			// console.log(this.$route.path == "/class");
			localStorage.setItem("catItem", JSON.stringify(item));
			// console.log(this.$route.path)
			// this.$router.push("/class?dataSrc=cache");
			this.$router.push({
				path: "/class",
				query: {
					dataSrc: "cache",
					name: item.name,
				},
			});
			// console.log("冒泡reInitCacheCat事件");
			this.$emit("reInitCacheCat");
		},
		goSearch() {
			let that = this;
			this.$router.replace(
				{
					name: "search",
					query: {
						search: this.search,
					},
				},
				() => {
					that.reload(); //刷新页面
				}
			);
			// this.$router.push(`/search?search=${this.search}`);
		},
		toCart() {
			this.$router.push("/cart");
		},
		goHome() {
			this.$router.push("/home");
		},
		goBuinessR() {
			this.$router.push("/business-r");
		},
		goWavfLogin() {
			if (this.isMobile()) {
				this.$router.push("/wavfloginMobile");
			} else {
				this.$router.push("/wavflogin");
			}
		},
		goUser() {
			this.$router.push("/user");
		},
		onMenuClick(cat) {
			if (cat) {
				if (cat?.list && cat.list.length > 0) {
					this.lastMenu.push(this.currentMenu);
					this.currentMenu = cat.list;
				} else {
					this.goClass(cat);
					this.onMenuClose();
				}
			}
		},
		onMenuBack() {
			this.currentMenu = this.lastMenu.pop();
		},
		onMenuOpen() {
			if (this.catList) {
				this.currentMenu = this.catList;
			}
			this.menuVisible = true;
		},
		onMenuClose() {
			this.menuVisible = false;
			this.currentMenu = undefined;
			this.lastMenu = [];
		},
		handleCommand(val) {
			sessionStorage.setItem("translateT", val);
			// console.log("11", val);
			translate.changeLanguage(val);
			translate.language.setDefaultTo(val);
			translate.service.use('client.edge');
			translate.execute();
		},
		async getExchangeRate(){
			const res = await commonApi({
				r: "api/default/exchange-rate",
			});
			sessionStorage.setItem('exchangeRateCAD', res.data?.data?.exchange_rate || '1.38');
		},
		toHelpCenter() {
			this.$router.push("/support");
		},
	},
};
</script>
<style>
.el-message-box__wrapper {
	z-index: 9999999 !important;
}
</style>
<style lang="scss" scoped>
// .item {
//   margin-top: 10px;
//   margin-right: 40px;
// }

.have-cart::after {
	content: " ";
	width: 10px;
	height: 10px;
	position: absolute;
	background-color: red;
	border-radius: 50%;
}

.personal-center {
	.logo-box {
		text-align: center;
	}

	.action-list {
		margin-bottom: 53px;

		.action-item-gap {
			height: 1px;
			opacity: 1;
			background: rgba(117, 117, 117, 1);
			// border-top: 1px solid rgba(117, 117, 117, 1);
			display: flex;
			margin-bottom: 20px;
		}

		width: 318px;
		height: 268px;
		opacity: 1;
		padding: 0px 30px 0px 30px;

		.action-item {
			cursor: pointer;
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			.action-name {
				height: 21px;
				opacity: 1;
				display: flex;

				/** 文本1 */
				font-size: 14px;
				font-weight: 300;
				letter-spacing: 0px;
				line-height: 21px;
				color: rgba(0, 0, 0, 1);
			}

			img {
				width: 20px;
				height: 20px;
				margin-right: 20px;
			}
		}
	}

	.pla-box {
		.email {
			height: 15px;
			opacity: 1;
			display: flex;
			/** 文本1 */
			font-size: 10px;
			font-weight: 300;
			letter-spacing: 0px;
			line-height: 14px;
			color: rgba(0, 0, 0, 1);
			text-align: left;
			vertical-align: middle;
		}

		.title {
			height: 42px;
			opacity: 1;
			display: flex;
			/** 文本1 */
			font-size: 30px;
			font-weight: 400;
			letter-spacing: 0px;
			line-height: 35.16px;
			color: rgba(0, 0, 0, 1);
			text-align: left;
			vertical-align: middle;
		}

		width: 318px;
		height: 97px;
		opacity: 1;
		border-radius: 5px;
		background: rgba(222, 229, 211, 1);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px 30px 20px 30px;
		margin-bottom: 14px;
	}

	width: 364px;
	height: 522px;
	opacity: 1;
	border-radius: 5px;
	background: rgba(255, 255, 255, 1);
	box-shadow: 0px 4px 6px 3px rgba(0, 0, 0, 0.25);
	padding: 20px 23px 20px 23px;
	position: absolute;
	z-index: 2006;
	top: 100px;
	right: 100px;
}

.hover-menu-box {
	cursor: pointer;
	padding-top: 20px;

	.right-box {
		.sl-menu-item {
			padding-left: 20px;
			min-height: 32px;
			line-height: 32px;
			color: #7f7f7f;
			cursor: pointer;
		}

		.sl-menu-item:hover {
			color: #000000;
		}
	}

	.left-box {
		width: 300px;
		padding-right: 20px;
		border-right: 1px solid #e3e3e3;
		min-height: 300px;
		height: auto;

		.menu-item {
			min-height: 32px;
			line-height: 32px;
			user-select: none;
			padding-left: 16px;
			display: flex;
			flex-direction: row;
			-webkit-box-align: center;

			place-items: center flex-start;
		}

		.menu-item:hover {
			font-weight: bold;
			background-color: #e9eef7;
		}

		.menu-item-active {
		}
	}

	width: 100vw;
	position: absolute;
	background-color: white;
	z-index: 2006;
	top: 150px;
	box-shadow: 0px 4px 5px -2px rgba(229, 229, 229, 0.6);
	padding-bottom: 20px;
}

::v-deep el-menu-demo el-menu--horizontal el-menu {
	height: 39px !important;
	line-height: 39px !important;

	cursor: pointer;
}

::v-deep .el-dropdown-menu__item,
.el-menu-item {
	font-size: 18px;
	color: #242830;
	height: 39px !important;
	line-height: 39px !important;
}

::v-deep .el-menu--horizontal > .el-menu-item.is-active {
	border: unset;
}

::v-deep .mains .el-submenu__title,
::v-deep .el-submenu__title {
	font-size: 18px !important;
	height: 39px !important;
	line-height: 39px !important;
	color: #242830 !important;
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
	height: 27px;
	line-height: 27px;
}

::v-deep .el-menu--horizontal > .el-menu-item {
	float: left;
	height: 27px;
	line-height: 27px;
	margin: 0;
	border-bottom: 2px solid transparent;
	color: #909399;
}

.main-h {
	background-color: white;
	height: 160px;
	position: -webkit-sticky;
	/* Safari */
	position: sticky;
	top: 0;
	z-index: 20;

	// min-width: 1280px;
	.header {
		height: 44px;

		img {
			width: 20px;
			height: 20px;
		}
	}

	.middle {
		margin: 0 54px;
		border-top: 1px solid #000;
		border-bottom: 1px solid #000;
		height: 68px;
		padding: 16px 0;

		.left {
			display: flex;
			align-items: center;
			width: 50%;

			.logo {
				margin: 0 54px 0 34px;

				img {
					min-width: 159px;
					height: 30px;
				}
			}

			.int {
				width: 100%;
				// height: 50px;
				position: relative;

				.search-icon {
					position: absolute;
					width: 23px;
					height: 23px;
					top: 50%;
					transform: translateY(-50%);
					right: 12px;
				}

				::v-deep .el-input__inner {
					border-radius: 50px;
					border: 1px solid #000;
					height: 34px;
					// display: block;
				}
			}
		}

		.right {
			font-size: 22px;

			.drop-d {
				img {
					width: 25px;
					height: 25px;
				}
			}

			.shu-line {
				width: 1px;
				height: 29px;
				background-color: #000;
				margin: 0 25px;
			}

			.sell-wavf {
				width: 150px;
			}

			.sgin-in {
				width: 80px;
			}

			.sign-up {
				color: #fff;
				background-color: #000;
				padding: 3px 27px 4px 26px;
				border-radius: 50px;
				width: 240px;
			}

			.logo-icon {
				width: 200px;
			}
		}
	}

	.fooder {
		height: 39px;

		.item-list {
			font-size: 18px;
			// margin: 0 44.5px;
			cursor: pointer;
		}
	}

	.bottom-bg {
		width: 100%;
		height: 10px;
		background-color: #788e86;
	}
}
</style>
