import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/home/index.vue";
// import { translate } from "@/utils/translate";
Vue.use(VueRouter);

const routes = [
	{
		path: "/store",
		name: "store",
		component: () => import("@/views/shopping/storeByLogined.vue"),
	},
	{
		path: "/",
		name: "home_base",
		component: HomeView,
	},
	{
		path: "/home",
		name: "home",
		component: HomeView,
	},
	// {
	//   path: "/selectAddress",
	//   name: "selectAddress",
	//   component: () => import("@/views/shopping/selectAddress.vue"),
	// },
	{
		path: "/shippingAddress",
		name: "shippingAddress",
		component: () => import("@/views/shopping/shippingAddress.vue"),
	},
	{
		path: "/checkout/success",
		name: "checkoutSuccess",
		component: () => import("@/views/shopping/checkoutSuccess.vue"),
	},
	{
		path: "/payMethod",
		name: "payMethod",
		component: () => import("@/views/shopping/payMethod.vue"),
	},
	{
		path: "/reviewItemsAndShipping",
		name: "reviewItemsAndShipping",
		component: () => import("@/views/shopping/reviewItemsAndShipping.vue"),
	},
	{
		path: "/login",
		name: "login",
		component: () => import("@/views/login/index.vue"),
	},
	{
		path: "/indexMobile",
		name: "indexMobile",
		component: () => import("@/views/login/indexMobile.vue"),
	},
	{
		path: "/wavflogin",
		name: "wavflogin",
		component: () => import("@/views/login/wavflogin.vue"),
	},
	{
		path: "/wavfloginMobile",
		name: "wavfloginMobile",
		component: () => import("@/views/login/wavfloginMobile.vue"),
	},
	{
		path: "/blog-content",
		name: "blog-content",
		component: () => import("@/views/blog/index.vue"),
	},
	{
		path: "/class",
		name: "class",
		component: () => import("@/views/class/index.vue"),
	},
	{
		path: "/wishlist",
		name: "wishlist",
		component: () => import("@/views/class/wishlist.vue"),
	},
	{
		path: "/bloghome",
		name: "bloghome",
		component: () => import("@/views/blog/bloghome.vue"),
	},
	{
		path: "/business",
		name: "business",
		component: () => import("@/views/blog/business.vue"),
	},
	{
		path: "/register",
		name: "register",
		component: () => import("@/views/register/index.vue"),
	},
	{
		path: "/business-r",
		name: "business-r",
		component: () => import("@/views/business/index.vue"),
	},
	{
		path: "/business-two",
		name: "business-two",
		component: () => import("@/views/business/business-two.vue"),
	},
	{
		path: "/business-four",
		name: "business-four",
		component: () => import("@/views/business/business-four.vue"),
	},
	{
		path: "/business-five",
		name: "business-five",
		component: () => import("@/views/business/business-five.vue"),
	},
	{
		path: "/business-sex",
		name: "business-sex",
		component: () => import("@/views/business/business-sex.vue"),
	},
	{
		path: "/business-seven",
		name: "business-seven",
		component: () => import("@/views/business/business-seven.vue"),
	},
	{
		path: "/business-eight",
		name: "business-eight",
		component: () => import("@/views/business/business-eight.vue"),
	},
	{
		path: "/business-nine",
		name: "business-nine",
		component: () => import("@/views/business/business-nine.vue"),
	},
	{
		path: "/business-ten",
		name: "business-ten",
		component: () => import("@/views/business/business-ten.vue"),
	},
	{
		path: "/business-eleven",
		name: "business-eleven",
		component: () => import("@/views/business/business-eleven.vue"),
	},
	{
		path: "/account-r",
		name: "account-r",
		component: () => import("@/views/business/account.vue"),
	},
	{
		path: "/business-i",
		name: "business-i",
		component: () => import("@/views/business/business-img.vue"),
	},
	{
		path: "/details",
		name: "details",
		component: () => import("@/views/shopping/details.vue"),
	},
	{
		path: "/cart",
		name: "cart",
		component: () => import("@/views/shopping/cart.vue"),
	},
	// {
	// 	path: "/store",
	// 	name: "store",
	// 	component: () => import("@/views/shopping/store.vue"),
	// },
	{
		path: "/checkout",
		name: "checkout",
		component: () => import("@/views/shopping/checkout.vue"),
	},

	{
		path: "/successwavf",
		name: "successwavf",
		component: () => import("@/views/blog/successwavf.vue"),
	},
	{
		path: "/policy",
		name: "policy",
		component: () => import("@/views/policy/index.vue"),
	},
	{
		path: "/search",
		name: "search",
		component: () => import("@/views/search/index.vue"),
	},
	{
		path: "/user",
		name: "user",
		redirect: "/user/my_profile",
		component: () => import("@/views/user/index.vue"),
		children: [
			{
				path: "my_profile",
				name: "user_profile",
				component: () => import("@/views/user/components/myprofile.vue"),
			},
			// {
			// 	path: "shops",
			// 	component: () => import("@/views/user/components/shops.vue"),
			// },
			{
				path: "pay_way",
				name: "user_pay_way",
				component: () => import("@/views/user/components/payway.vue"),
			},
			{
				path: "ship_address",
				name: "user_ship_address",
				component: () => import("@/views/user/components/ship_address.vue"),
			},
			// {
			// 	path: "email_pres",
			// 	component: () => import("@/views/user/components/email_pres.vue"),
			// },
			// {
			// 	path: "my_team",
			// 	component: () => import("@/views/user/components/my_team.vue"),
			// },
			{
				path: "security",
				name: "user_security",
				component: () => import("@/views/user/components/security.vue"),
			},
			{
				path: "order",
				name: "user_order",
				component: () => import("@/views/user/components/order.vue"),
			},
		],
	},
	{
		path: "/404",
		name: "not_found",
		component: () => import("@/views/error-page/index.vue"),
	},
	{
		path: "/contact_us",
		name: "contact_us",
		component: () => import("@/views/contact/contact_us.vue"),
	},
	{
		path: "/invoice",
		name: "invoice",
		component: () => import("@/views/invoice/index.vue"),
	},
	{
		path: "/support",
		name: "support",
		component: () => import("@/views/support/support.vue"),
	},
    {
		path: "/brands_terms_of_use",
		name: "brands_terms_of_use",
		component: () => import("@/views/support/brands_terms_of_use.vue"),
	},
	{
		path: "/retailer_terms_of_use",
		name: "retailer_terms_of_use",
		component: () => import("@/views/support/retailer_terms_of_use.vue"),
	},
	{
		path: "/about_detail",
		name: "about_detail",
		component: () => import("@/views/support/about_detail.vue"),
	},
	{
		path: "/help/wavf_buying_process",
		name: "wavf_buying_process",
		component: () => import("@/views/support/help/wavf_buying_process.vue"),
	},
	{
		path: "/help/wavf_select_brands",
		name: "wavf_select_brands",
		component: () => import("@/views/support/help/wavf_select_brands.vue"),
	},
	{
		path: "/help/wavf_handle_shipping",
		name: "wavf_handle_shipping",
		component: () => import("@/views/support/help/wavf_handle_shipping.vue"),
	},
	{
		path: "/help/track_shipment",
		name: "track_shipment",
		component: () => import("@/views/support/help/track_shipment.vue"),
	},
	{
		path: "/help/wavf_handle_return",
		name: "wavf_handle_return",
		component: () => import("@/views/support/help/wavf_handle_return.vue"),
	},
	{
		path: "/help/information_safety",
		name: "information_safety",
		component: () => import("@/views/support/help/information_safety.vue"),
	},
	{
		path: "/help/payment_safety",
		name: "payment_safety",
		component: () => import("@/views/support/help/payment_safety.vue"),
	},
	{
		path: "/help/return_on_wavf",
		name: "return_on_wavf",
		component: () => import("@/views/support/help/return_on_wavf.vue"),
	},
	{
		path: "/help/contact_brands_support",
		name: "contact_brands_support",
		component: () => import("@/views/support/help/contact_brands_support.vue"),
	},
	{
		path: "/help/access_order_history_taxes",
		name: "access_order_history_taxes",
		component: () => import("@/views/support/help/access_order_history_taxes.vue"),
	},
	{
		path: "/password/forget",
		name: "forgetPassword",
		component: () => import("@/views/password/forget.vue"),
	},
	{
		path: "/resetPassword",
		name: "resetPassword",
		component: () => import("@/views/password/reset.vue"),
	},
	{
		path: "/emailVerify",
		name: "emailVerify",
		component: () => import("@/views/email/verify"),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
	// chrome
	document.body.scrollTop = 0;
	// firefox
	document.documentElement.scrollTop = 0;
	// safari
	window.pageYOffset = 0;
	next();
	try {
		let langer = sessionStorage.getItem("translateT"); //页面刷新后能获取到之前设置的语种
		let timed = null;
		if (langer) {
			if (timed) {
				clearTimeout(timed);
			}
			timed = setTimeout(() => {
				clearTimeout(timed);
				translate.language.setDefaultTo(langer);
				translate.service.use("client.edge");
				translate.execute();
			}, 500);
		}
	} catch (error) {}
});

export default router;
